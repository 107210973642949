import React, { useCallback, useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { useHistory, useParams, Link } from "react-router-dom";

import "./user.scss";
import {
  adminRequestPost,
  adminUserPut,
  formPost,
  requestDelete,
  requestGet,
} from "../../apis/request";
import { ReactComponent as Paste } from "../../assets/icon/svgs/light/paste.svg";
import { ReactComponent as Bars } from "../../assets/icon/svgs/solid/bars.svg";
import moment from "moment";
import IntroMemberList from "../Member/IntroMemberList";
import HaveBeenIntroducedList from "../Member/HaveBeenIntroducedList";

import PostLikeList from "../Member/PostLikeList";
import GetLikeList from "../Member/GetLikeList";
import MemberOption from "./MemberOption";
import styled from "styled-components";
import CashHistory from "../Member/CashHistory";
import LikeHistory from "../Member/LikeHistory";
import CashPost from "../Member/CashPost";
import AuthPage from "../Member/AuthPage";
import CommonConstant from "../../common/CommonConstant";
import loadImage from "blueimp-load-image";
import MileageHistory from "../Member/MileageHistory";
import SweetAlert from "react-bootstrap-sweetalert";
import DraggableItem from "../../components/DraggableItem";
import MemberErrorModal from "../Member/Modal/MemberErrorModal";
import TimePage from "../Member/TimePage";
import OneMoreIntroModal from "../Member/Modal/OneMoreIntroModal";
import {useIntroMemberListStore} from "../../store/zustand/state";
import {useHaveBeenIntroducedListStore} from "../../store/zustand/state";
//lch1721 현재 회원 정보
function Member(props) {
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [values, setValues] = useState({
    ideal_industry: [],
    ideal_allowRegion: [],
  });
  const [attributes, setAttributes] = useState([]);
  const [data, setData] = useState(null);
  const [recommendcode, setRecommendcodeData] = useState(null);
  const [joinfeedata, setjoinfeeData] = useState(null);
  const [marriagefeedata, setmarriagefeeData] = useState(null);
  const [acceptPostfile, setAcceptPostfile] = useState([]);
  const [publicPostfile, setPublicPostfile] = useState([]);
  const [privitePostfile, setPrivitePostfile] = useState([]);
  const [videoPostfile, setVideoPostfile] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [sido, setSido] = useState("");
  const [sidoTrue, setSidoTrue] = useState("");
  const [sidoJob, setSidoJob] = useState("");
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [hour, setHour] = useState("09");
  const [minute, setMinute] = useState("00");
  const [filekey, setFilekey] = useState(
    moment().format("YYYY-MM-DD HH:mm:ss")
  );
  const [filekey1, setFilekey1] = useState(
    moment().format("YYYY-MM-DD HH:mm:ss")
  );
  const [filekey2, setFilekey2] = useState(
    moment().format("YYYY-MM-DD HH:mm:ss")
  );
  const [filekeyVideo, setFilekeyVideo] = useState(
    moment().format("YYYY-MM-DD HH:mm:ss")
  );
  const [memberList, setMemberList] = useState([]);
  const [timesent, setTimesent] = useState(false);
  const [testphone, setTestphone] = useState("");
  const [testname, setTestName] = useState("");
  const [introMsg, setIntroMsg] = useState(null);
  const [testdisphone, setTestdisphone] = useState("");
  const [managers, setManagers] = useState([]);
  const [authData, setAuthData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [navSee, setNavSee] = useState(false);
  const [imgModal, setImgModal] = useState(null);
  const [tags, setTags] = useState([
    "기본 정보",
    "학력",
    "직업",
    "자산정보",
    "가족관계",
    "재혼 정보",
    "본인 상세정보",
    "이상형 우선순위",
    "이상형 기피사항",
    "이상형 상세정보",
  ]);
  const history = useHistory();
  const [joinFromData, setJoinFromData] = useState([]);
  const { id } = useParams();
  const { updateList } = useIntroMemberListStore();
  const { updateIntroudcedList } = useHaveBeenIntroducedListStore();

  const onDragStart = (e, id) => {
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("imgIndex", String(id));
  };
  const onDragalertStart = (e, id) => {
    alert("등록 후 순서 변경이 가능합니다.");
  };
  const onDragDrop = (e, index) => {
    e.preventDefault();

    const sourceIndex = Number(e.dataTransfer.getData("imgIndex"));
    if (sourceIndex === index) return;
    const updateImages = [...values?.publicImages];
    const [movedImage] = updateImages.splice(sourceIndex, 1);

    updateImages.splice(index, 0, movedImage);
    setValues({
      ...values,
      publicImages: updateImages,
    });
  };
  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDragPriviteStart = (e, id) => {
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("imgIndex", String(id));
  };
  const onDragPriviteDrop = (e, index) => {
    e.preventDefault();

    const sourceIndex = Number(e.dataTransfer.getData("imgIndex"));
    if (sourceIndex === index) return;
    const updateImages = [...values?.secretImages];
    const [movedImage] = updateImages.splice(sourceIndex, 1);

    updateImages.splice(index, 0, movedImage);
    setValues({
      ...values,
      secretImages: updateImages,
    });
  };
  const onDragPriviteOver = (e) => {
    e.preventDefault();
  };

  const onDragVideoStart = (e, id) => {
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("videoIndex", String(id));
  };

  const onDragVideoDrop = (e, index) => {
    e.preventDefault();

    const sourceIndex = Number(e.dataTransfer.getData("videoIndex"));
    if (sourceIndex === index) return;
    const updateVideos = [...values.videos];
    const [movedVideo] = updateVideos.splice(sourceIndex, 1);

    updateVideos.splice(index, 0, movedVideo);
    setValues({
      ...values,
      videos: updateVideos,
    });
  };

  const onDragVideoOver = (e) => {
    e.preventDefault();
  };
  const callManagerApi = async () => {
    try {
      const response = await requestGet(`/api/counseling/manager/list`);
      setManagers(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const callApi = async () => {
    try {
      let joinArr = [];
      let marrArr = [];
      const response = await requestGet(`/api/product/list/admin`);
      const isPublicResponse = await requestGet(
        `/api/product/list/admin?isPublic=false`
      );
      response?.data?.forEach((item) => {
        if (item.type === "joinFee" ) {
          joinArr.push(item);
        } else if( item.type === "subscribe") {
          item.name = "기간제 " + item.name;
          joinArr.push(item);
        } else if (item.type === "marriageFee") {
          marrArr.push(item);
        }
      });
      isPublicResponse?.data?.forEach((item) => {
        if (item.id === data.joinFee) {
          joinArr.unshift(item);
        }
      });
      setjoinfeeData(joinArr);
      setmarriagefeeData(marrArr);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    callManagerApi();
    callApi();
  }, [data]);

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 10) {
        
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
        updateList(1);
      } else if(tab === 11) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
        updateIntroudcedList(1);
      }
    }
  }

  const callInfo = async () => {
    try {
      const response = await requestGet("/api/attributes/list");
      setAttributes(response?.data?.industry?.value);
    } catch (error) {
      console.log(error);
    }
  };

  const callLocation = async () => {
    try {
      const response = await requestGet("/api/region/list/json");
      setLocationData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    callInfo();
    callLocation();
  }, []);

  const onFileHandler = (e) => {
    e.stopPropagation();
    let files = e.target?.files;
    Promise.all(
      Array.from(files).map((file) =>
        loadImage(file, { meta: true, canvas: true, orientation: true }).then(
          (img) =>
            new Promise((resolve, reject) => {
              img.image.toBlob(
                (blob) => {
                  const rotatedFile = new File([blob], file.name, {
                    type: file.type,
                  });
                  resolve(rotatedFile);
                },
                file.type,
                (error) => reject(error)
              );
            })
        )
      )
    )
      .then((rotatedFiles) => {
        const updatedFiles = [
          ...acceptPostfile,
          ...rotatedFiles.map((file) => ({
            file,
            id: `${file.name}${moment().format("YYYY-MM-DD HH:mm:ss")}`,
          })),
        ];
        setAcceptPostfile(updatedFiles);
        setFilekey(moment().format("YYYY-MM-DD HH:mm:ss"));
      })
      .catch((error) => {
        console.error("Error processing files", error);
      });
  };

  const removeFile = (fileId) => {
    const filteredFiles = acceptPostfile.filter((file) => file.id !== fileId);
    setAcceptPostfile(filteredFiles);
  };

  const onFileHandler1 = (e) => {
    e.stopPropagation();
    let files = e.target?.files;
    Promise.all(
      Array.from(files).map((file) =>
        loadImage(file, { meta: true, canvas: true, orientation: true }).then(
          (img) =>
            new Promise((resolve, reject) => {
              img.image.toBlob(
                (blob) => {
                  const rotatedFile = new File([blob], file.name, {
                    type: file.type,
                  });
                  resolve(rotatedFile);
                },
                file.type,
                (error) => reject(error)
              );
            })
        )
      )
    )
      .then((rotatedFiles) => {
        const updatedFiles = [...publicPostfile];
        rotatedFiles.forEach((file) => {
          updatedFiles.unshift({
            file,
            id: `${file.name}${moment().format("YYYY-MM-DD HH:mm:ss")}`,
          });
        });
        setPublicPostfile(updatedFiles);
        setFilekey1(moment().format("YYYY-MM-DD HH:mm:ss"));
      })
      .catch((error) => {
        console.error("Error processing files", error);
      });
  };

  const removeFile1 = (fileId) => {
    const filteredFiles = publicPostfile.filter((file) => file.id !== fileId);
    setPublicPostfile(filteredFiles);
  };

  const onFileHandler2 = (e) => {
    e.stopPropagation();
    let files = e.target?.files;
    Promise.all(
      Array.from(files).map((file) =>
        loadImage(file, { meta: true, canvas: true, orientation: true }).then(
          (img) =>
            new Promise((resolve, reject) => {
              img.image.toBlob(
                (blob) => {
                  const rotatedFile = new File([blob], file.name, {
                    type: file.type,
                  });
                  resolve(rotatedFile);
                },
                file.type,
                (error) => reject(error)
              );
            })
        )
      )
    )
      .then((rotatedFiles) => {
        const updatedFiles = [...privitePostfile];
        rotatedFiles.forEach((file) => {
          updatedFiles.unshift({
            file,
            id: `${file.name}${moment().format("YYYY-MM-DD HH:mm:ss")}`,
          });
        });
        setPrivitePostfile(updatedFiles);
        setFilekey2(moment().format("YYYY-MM-DD HH:mm:ss"));
      })
      .catch((error) => {
        console.error("Error processing files", error);
      });
  };

  const removeFile2 = (fileId) => {
    const filteredFiles = privitePostfile.filter((file) => file.id !== fileId);
    setPrivitePostfile(filteredFiles);
  };

  const onVideoFileHandler = (e) => {
    e.stopPropagation();
    let files = e.target?.files;
    const updatedFiles = [
      ...videoPostfile,
      ...Array.from(files).map((file) => ({
        file,
        id: `${file.name}${moment().format("YYYY-MM-DD HH:mm:ss")}`,
      })),
    ];
    setVideoPostfile(updatedFiles);
    setFilekeyVideo(moment().format("YYYY-MM-DD HH:mm:ss"));
  };

  const removeFileVideo = (fileId) => {
    const filteredFiles = videoPostfile.filter((file) => file.id !== fileId);
    setVideoPostfile(filteredFiles);
  };

  const deleteFile = async (fileId, imageType) => {
    if (window.confirm("이미지를 삭제 하시겠습니까?")) {
      try {
        await requestDelete(`/api/file/${fileId}`);
        // 파일 삭제 성공 후, data 객체에서 해당 이미지 정보 제거
        setData((prevData) => {
          // imageType에 따라 해당하는 이미지 배열을 복사해서 새 배열을 만듦
          const updatedImages = prevData[imageType].filter(
            (image) => image.id !== fileId
          );
          // 새로운 객체를 반환하여 상태를 업데이트
          return { ...prevData, [imageType]: updatedImages };
        });
        alert("이미지 삭제 성공");
      } catch (error) {
        console.error("이미지 삭제 실패:", error);
        alert("이미지 삭제 실패");
      }
    }
  };

  const selOption = MemberOption;

  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      const msg = successful ? "successful" : "unsuccessful";
      alert("Copied to clipboard");
      console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
      alert("Copy failed");
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  };

  const handleCopyToClipboard = async (text) => {
    if (navigator.clipboard) {
      // 클립보드 API 지원 여부 확인
      try {
        await navigator.clipboard.writeText(text);
        alert("Copied to clipboard");
      } catch (error) {
        console.error("Copy failed", error);
        alert("Copy failed");
      }
    } else {
      fallbackCopyTextToClipboard(text);
    }
  };

  const callAPI = useCallback(async () => {
    const response = await requestGet(`/api/member/detail/${id}`);
    setData(response.data);
  }, [id]);

  const callCodeAPI = async () => {
    const response = await requestGet(`/api/recommendcode/list`);
    setRecommendcodeData(response.data);
  };

  const calljoinCodeAPI = async () => {
    const response = await requestGet(`/api/join/from/list`);
    setJoinFromData(response.data);
  };

  useEffect(() => {
    calljoinCodeAPI();
    callCodeAPI();
    if (id) {
      callAPI();
    }
  }, [callAPI, id]);

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeBoolean = (e) => {
    setValues({
      ...values,
      [e.target.name]:
        e.target.value === "true"
          ? true
          : e.target.value === "false"
          ? false
          : "",
    });
  };
  const handleChangeOriginalRadio = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      [e.target.name + "etc"]: "",
    });
  };

  const handleChangerecommand = (e) => {
    if (e.target.value === "기타") {
      setValues({
        ...values,
        [e.target.name]: "",
        [e.target.name + "recommand"]: e.target.value,
      });
    } else {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
        [e.target.name + "recommand"]: e.target.value,
      });
    }
  };

  const handleChangeBlur = (e) => {
    console.log("handleChangeBlur call");
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      [e.target.name.replace(/etc$/, "")]: e.target.value,
    });
  };

  const getInputValue = (valuesInput, keyPropInput, valueInput) => {
    const etcValue = valuesInput?.[keyPropInput + "etc"];
    const keyValue = valuesInput?.[keyPropInput];
    if (etcValue !== undefined && etcValue !== null) {
      return etcValue;
    } else if (
      keyValue &&
      !valueInput.includes(keyValue) &&
      keyValue !== "기타"
    ) {
      return keyValue;
    }
    return ""; // 두 조건에 맞지 않는 경우 빈 문자열 반환
  };

  const handleChangeEtc = (e) => {
    console.log("handleChangeEtc: " + e.target.name);
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleMoneyChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // 숫자가 아닌 모든 문자 제거
    const formattedNumber = value.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // 천 단위마다 콤마 추가
    setValues({
      ...values,
      [e.target.name]: formattedNumber,
    });
  };

  const handleCheck = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => {
      let updatedArray = prevValues[name] ? [...prevValues[name]] : [];
      if (prevValues[name]?.includes(Number(value))) {
        const arr = updatedArray.filter((_value) => _value !== Number(value));
        updatedArray = arr;
      } else {
        updatedArray.push(Number(value));
      }
      return { ...prevValues, [name]: updatedArray };
    });
  };

  const handleSidoChange = (e) => {
    if (e.target.name === "customAdress") {
      setSido(e.target.value);
    } else if (e.target.name === "jobaddres") {
      setSidoJob(e.target.value);
    } else {
      setSidoTrue(e.target.value);
    }
    if (e.target.value === "해외") {
      let str = "";
      locationData.forEach((item) => {
        if (item?.name === "해외") {
          str = item.list[0].regionCode;
        }
      });
      setValues({
        ...values,
        [e.target.name]: e.target.value,
        [e.target.name + "googun"]: str,
      });
    } else {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
        [e.target.name + "googun"]: "",
      });
    }
  };

  const handleCheckstr = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => {
      let updatedArray = prevValues[name] ? [...prevValues[name]] : [];
      if (prevValues[name]?.includes(value)) {
        const arr = updatedArray.filter((_value) => _value !== value);
        updatedArray = arr;
      } else {
        updatedArray.push(value);
      }
      return { ...prevValues, [name]: updatedArray };
    });
  };

  useEffect(() => {
    if (data !== null) {
      let obj = {};
      let arr = [];
      setSido(data.homeRegionCode?.sidoKr);
      setSidoTrue(data.parentHomeRegionCode?.sidoKr);
      setSidoJob(data.jobinfo?.jobRegionCode?.sidoKr);
      data.idealInfo?.industry.forEach((item) => arr.push(item.id));
      obj.id = data.id;
      obj.profileId = data.profileId;
      obj.name = data.name;
      obj.status = data.status;
      obj.gender = data.gender;
      obj.userCode = data.userCode;
      obj.birth = moment(data.birthday).format("YYYY-MM-DD");
      // recommendCode =  =data.추천 코드
      // counselorId =  1=data.이부분은 별개의 검색 api로 아이디를 받게끔 구성해야 할지도 ... 우선은 아이디로 전달하게 해주세요.
      // 프로필 정보
      obj.ismarry = data.isMarry;
      obj.height = data.height;
      obj.weight = data.weight;
      obj.matchedUser = data.matchedUser;
      obj.phone = data.phone;
      obj.email = data.email;
      setEmail(data.email);
      obj.userMarriageFee = data.userMarriageFee;
      obj.joinFee = data.joinFee;
      obj.joinFeeID = data.joinFee;
      obj.counselor = data.counselor;
      obj.recommendCode = data.recommendCode;
      obj.profileConfirm = data.profileConfirm;
      obj.likeTotal = data?.finalLikeCoupon;
      obj.likeFreeTotal = data?.finalFreeLikeCoupon;
      obj.totalScore = data.totalScore;
      obj.appearanceScore = data.appearanceScore;
      obj.membershipStartDate = data.membershipStartDate;
      obj.membershipEndDate = data.membershipEndDate;
      obj.membershipUseCount = data.membershipUseCount;
      obj.membershipTotalCount = data.membershipTotalCount;
      obj.dayCountingAfterIntroduce =
        Number(data.dayCountingAfterIntroduce) > 0
          ? data.dayCountingAfterIntroduce
          : 0;
      obj.specsScore = data.specsScore;
      obj.joinFrom = data.joinFrom;
      obj.joinFromrecommand = data.joinFrom
        ? joinFromData?.includes(data.joinFrom)
          ? data.joinFrom
          : "기타"
        : "";
      obj.wealthScore = data.wealthScore;
      obj.comment = data.comment;
      obj.secretComment = data.secretComment;
      obj.customAdress = data.homeRegionCode?.sidoKr;
      obj.customAdressgoogun = data.homeRegionCode?.regionCode;
      obj.customAdressdetail = data.home;
      obj.tureHome = data.parentHomeRegionCode?.sidoKr;
      obj.tureHomegoogun = data.parentHomeRegionCode?.regionCode;
      obj.tureHomedetail = data.parentHome;
      obj.jobaddres = data.jobinfo?.jobRegionCode?.sidoKr;
      obj.jobaddresgoogun = data.jobinfo?.jobRegionCode?.regionCode;
      obj.jobaddresdetail = data.jobinfo?.address;
      obj.myface = data.selfAppearance;
      obj.mbti = data.mbti;
      obj.bodyCharacter = data.bodyCharacter;
      obj.marriageStartDate = moment(data.marriageStartDate).format(
        "YYYY-MM-DD"
      );
      obj.marriageEndDate = moment(data.marriageEndDate).format("YYYY-MM-DD");
      obj.reason = data.reason;
      obj.care = data.care;
      obj.childrenNumber = data.childrenNumber;
      obj.drinkBottleLimit = data.drinkBottleLimit;
      obj.drinkCupLimit = data.drinkCupLimit;
      obj.drink = data.drink;
      obj.smoke = data.smoke;
      obj.bloodType = data.bloodType;
      obj.tatoo = data.tatoo;
      obj.sport = data.exercise;
      obj.military = data.military;
      obj.religion = data.religion;
      obj.myhobby = data.hobby;
      obj.mystyle = data.character;
      obj.finaledu = data?.education?.finalStage;
      obj.hakname = data?.education?.univName;
      obj.hakskill = data?.education?.univMajor;
      obj.hakenddate = data?.education?.univGradyear;
      obj.sukname = data?.education?.gradSchoolName;
      obj.sukskill = data?.education?.gradSchoolMajor;
      obj.sukenddate = data?.education?.gradSchoolGradyear;

      obj.jobname = data?.jobinfo?.job;
      obj.career = data?.jobinfo?.career;
      obj.etcjobname = data?.jobinfo?.extraJob;
      obj.jobstatus = data?.jobinfo?.employmentType;
      obj.workstatus = data?.jobinfo?.workType;
      obj.yearIncomeExtraComment = data?.jobinfo?.extraComment;
      obj.yearmoney = data?.jobinfo?.yearIncome
        ? String(data?.jobinfo?.yearIncome).replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )
        : "0";
      obj.Authyearmoney = data?.jobinfo?.checkYearIncome
        ? String(data?.jobinfo?.checkYearIncome).replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )
        : "0";
      obj.comname = data?.jobinfo?.name;
      obj.department = data?.jobinfo?.department;
      obj.level = data?.jobinfo?.position;
      obj.movemoney = data?.assets?.movableAmount
        ? String(data?.assets?.movableAmount).replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )
        : "0";
      obj.Authmovemoney = data?.assets?.checkMovableAmount
        ? String(data?.assets?.checkMovableAmount).replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )
        : "0";
      obj.nomovemoney = data?.assets?.immovableAmount
        ? String(data?.assets?.immovableAmount).replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )
        : "0";
      obj.Authnomovemoney = data?.assets?.checkImmovableAmount
        ? String(data?.assets?.checkImmovableAmount).replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )
        : "0";
      obj.hasnomovemoney = data?.assets?.hasImmovable;
      obj.movableExtraComment = data?.assets?.extraComment;
      obj.immovableExtraComment2 = data?.assets?.extraComment2;
      obj.merrymoney = data?.assets?.assistance;
      // obj.debt = data?.assets?.debt
      //   ? String(data?.assets?.debt).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      //   : "0";
      obj.debt = data?.assets?.checkDebt
        ? String(data?.assets?.checkDebt).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "0";
      obj.hascar = data?.assets?.hasCar;
      obj.makecarname = data?.assets?.madeBy;
      obj.carname = data?.assets?.carModelName;
      obj.assistanceExtra = data?.assets?.assistanceExtra
        ? String(data?.assets?.assistanceExtra).replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )
        : "0";
      obj.familyinfo = data?.familyRelation?.type;
      obj.brothers = data?.familyRelation?.brothers;
      obj.sisters = data?.familyRelation?.sisters;
      obj.birthOrder = data?.familyRelation?.birthOrder;
      obj.fajob = data?.familyRelation?.fatherJob;
      obj.isOpenFatherJob = data?.familyRelation?.isOpenFatherJob;
      obj.mojob = data?.familyRelation?.motherJob;
      obj.isOpenMotherJob = data?.familyRelation?.isOpenMotherJob;
      obj.isOpenParentAsset = data?.familyRelation?.isOpenParentAsset;
      obj.isOpenCheckParentAsset = data?.familyRelation?.isOpenCheckParentAsset;
      obj.isOpenParentImmovableAmount =
        data?.familyRelation?.isOpenParentImmovableAmount;
      obj.isOpenCheckParentImmovableAmount =
        data?.familyRelation?.isOpenCheckParentImmovableAmount;
      obj.isOpenParentDebt = data?.familyRelation?.isOpenParentDebt;
      obj.isOpenCheckParentDebt = data?.familyRelation?.isOpenCheckParentDebt;
      obj.knowParentAsset = data?.familyRelation?.knowParentAsset;
      obj.pamoney = data?.familyRelation?.parentAsset
        ? String(data?.familyRelation?.parentAsset).replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )
        : "0";
      obj.Authpamoney = data?.familyRelation?.checkParentAsset
        ? String(data?.familyRelation?.checkParentAsset).replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )
        : "0";
      obj.parentImmovableAmount = data?.familyRelation?.parentImmovableAmount
        ? String(data?.familyRelation?.parentImmovableAmount).replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )
        : "0";
      obj.AuthparentImmovableAmount = data?.familyRelation
        ?.checkParentImmovableAmount
        ? String(data?.familyRelation?.checkParentImmovableAmount).replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )
        : "0";
      // obj.parentDebt = data?.familyRelation?.parentDebt
      //   ? String(data?.familyRelation?.parentDebt).replace(
      //       /\B(?=(\d{3})+(?!\d))/g,
      //       ","
      //     )
      //   : "0";
      obj.parentDebt = data?.familyRelation?.checkParentDebt
        ? String(data?.familyRelation?.checkParentDebt).replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )
        : "0";
      obj.livealone = data?.familyRelation?.isLiveAlone;
      obj.pawithlive = data?.familyRelation?.withParent;
      obj.paalone = data?.familyRelation?.parentIndep;
      obj.paend = data?.familyRelation?.isSplit;
      obj.pasad = data?.familyRelation?.parentStatus;
      obj.parentExtraComment = data?.familyRelation?.extraComment;

      obj.ideal_birthYearMin = data?.idealInfo?.birthYearMin;
      obj.ideal_birthYearMax = data?.idealInfo?.birthYearMax;
      obj.ideal_heightMin = data?.idealInfo?.heightMin;
      obj.ideal_heightMax = data?.idealInfo?.heightMax;
      obj.ideal_education = data?.idealInfo?.education;
      obj.ideal_appearance = data?.idealInfo?.appearance;
      obj.ideal_industry = arr;
      obj.ideal_hasImmovable = data?.idealInfo?.hasImmovable;
      obj.ideal_religion = data?.idealInfo?.religion;
      try {
        obj.ideal_allowRegion = data?.idealInfo?.allowRegion
          ? JSON.parse(data?.idealInfo?.allowRegion)
          : null;
      } catch (error) {
        if (data?.idealInfo?.allowRegion) {
          obj.ideal_allowRegion = [];
          obj.ideal_allowRegion[0] = data?.idealInfo?.allowRegion;
        }
        // obj.ideal_allowRegion = data?.idealInfo?.allowRegion
        //   ? [data?.idealInfo?.allowRegion]
        //   : null;
      }
      obj.ideal_income = data?.idealInfo?.income;
      obj.ideal_personalAsset = data?.idealInfo?.personalAsset;
      obj.ideal_parentalAsset = data?.idealInfo?.parentalAsset;
      // ideal_religionGrade: null,
      obj.ideal_caracter = data?.idealInfo?.character;
      obj.ideal_priority = data?.idealInfo?.priority;
      obj.hateIndustry = data?.idealInfo?.hateIndustry;
      obj.hateMarriageHistory = data?.idealInfo?.hateMarriageHistory;
      obj.hateSmokeType = data?.idealInfo?.hateSmokeType;
      obj.hateTatoo = data?.idealInfo?.hateTatoo;
      obj.isDink = data?.idealInfo?.isDink;
      obj.hateParentStatus = data?.idealInfo?.hateParentStatus;
      obj.hateHasCarType = data?.idealInfo?.hateHasCarType;
      obj.idealHateDetail = data?.idealInfo?.idealHateDetail;
      obj.ideal_religion = data?.idealInfo?.religion;
      try {
        obj.hateEmploymentType = data?.idealInfo?.hateEmploymentType
          ? JSON.parse(data?.idealInfo?.hateEmploymentType)
          : null;
      } catch (error) {
        if (data?.idealInfo?.hateEmploymentType) {
          obj.hateEmploymentType = [];
          obj.hateEmploymentType[0] = data?.idealInfo?.hateEmploymentType;
        }
      }
      obj.hateInterest = data?.idealInfo?.hateInterest;
      obj.hopeStyle = data?.idealInfo?.idealDetail;
      obj.priority1 = data?.idealInfo?.priority1;
      obj.priority2 = data?.idealInfo?.priority2;
      obj.priority3 = data?.idealInfo?.priority3;
      obj.authImages = data?.authImages;
      obj.publicImages = data?.publicImages;
      obj.secretImages = data?.secretImages;
      obj.videos = data?.videos;
      obj.dDay = data?.dDay;
      setValues(obj);
      setAuthData({
        asset: data?.profileAuthentication?.asset,
        extra: data?.profileAuthentication?.extra,
        hasJob: data?.profileAuthentication?.hasJob,
        idVerify: data?.profileAuthentication?.idVerify,
        img: data?.profileAuthentication?.img,
        selfIntro: data?.profileAuthentication?.selfIntro,
        univ: data?.profileAuthentication?.univ,
        yearIncome: data?.profileAuthentication?.yearIncome,
        yearIncome2: data?.profileAuthentication?.yearIncome2,
      });
    }
  }, [data, joinFromData]);

  const onDeleteSubmitApi = async () => {
    if (window.confirm("삭제하시겠습니까?")) {
      try {
        await requestDelete(`/api/member/${values.id}`);
        alert("삭제 성공 하였습니다.");
        window.location.replace("/member");
      } catch (error) {
        alert("삭제 실패 하였습니다.");
      }
    }
  };
  const onSubmitApi = async (userstatus) => {
    if (Number(values.brothers) + Number(values.sisters) < values.birthOrder) {
      return alert("형제관계를 다시 한번 확인해주세요.");
    }
    if (
      moment(values.marriageStartDate).isAfter(moment(values.marriageEndDate))
    ) {
      return alert(
        "결혼기간 시작날짜가 결혼기간 끝날짜 보다 늦을 수 없습니다."
      );
    }
    if (
      values.ideal_birthYearMin &&
      values.ideal_birthYearMax &&
      Number(values.ideal_birthYearMin) > Number(values.ideal_birthYearMax)
    ) {
      return alert("최소 나이가 최대 나이 보다 클 수 없습니다.");
    }
    if (
      values.ideal_heightMin &&
      values.ideal_heightMax &&
      Number(values.ideal_heightMin) > Number(values.ideal_heightMax)
    ) {
      return alert("최소 키가 최대 키 보다 클 수 없습니다.");
    }
    if (
      values.ideal_heightMin &&
      values.ideal_heightMax &&
      (Number(values.ideal_heightMin) < 100 ||
        Number(values.ideal_heightMax) < 100)
    ) {
      return alert("키 입력시 100 이상으로 입력해 주세요");
    }
    if (values.customAdress && !values.customAdressgoogun) {
      return alert("거주지역 시/도 선택시 시/구/군 은 필수선택 입니다.");
    }
    if (values.jobaddres && !values.jobaddresgoogun) {
      return alert("회사소재지 시/도 선택시 시/구/군 은 필수선택 입니다.");
    }
    if (values.tureHome && !values.tureHomegoogun) {
      return alert("본가 시/도 선택시 시/구/군 은 필수선택 입니다.");
    }
    let arr = [];
    if (
      window.confirm(
        userstatus === "accept"
          ? "승인 하시겠습니까?"
          : userstatus === "pending"
          ? "보류 하시겠습니까?"
          : userstatus === "reject"
          ? "거절 하시겠습니까?"
          : "수정 하시겠습니까?"
      )
    ) {
      try {
        setLoading(true);
        if (publicPostfile.length > 0) {
          var bodyFormData1 = new FormData();
          bodyFormData1.append("profileId", values.profileId);
          bodyFormData1.append("userId", values.id);
          bodyFormData1.append("isAuthImage", false);
          bodyFormData1.append("isPublic", true);
          publicPostfile?.map((item) => {
            bodyFormData1.append("files", item.file);
          });
          const puResponse = await formPost(
            `/api/file/uploads/v2`,
            bodyFormData1
          );
          if (puResponse?.data?.length > 0) {
            puResponse.data.forEach((item) => arr.push(item.id));
          }
        }
        if (privitePostfile.length > 0) {
          var bodyFormData2 = new FormData();
          bodyFormData2.append("profileId", values.profileId);
          bodyFormData2.append("userId", values.id);
          bodyFormData2.append("isAuthImage", false);
          bodyFormData2.append("isPublic", false);
          privitePostfile?.map((item) => {
            bodyFormData2.append("files", item.file);
          });
          const prResponse = await formPost(
            `/api/file/uploads/v2`,
            bodyFormData2
          );
          if (prResponse?.data?.length > 0) {
            prResponse.data.forEach((item) => arr.push(item.id));
          }
        }
        if (videoPostfile.length > 0) {
          var bodyFormData3 = new FormData();
          bodyFormData3.append("profileId", values.profileId);
          bodyFormData3.append("userId", values.id);
          bodyFormData3.append("isAuthImage", false);
          bodyFormData3.append("isPublic", false);
          videoPostfile?.map((item) => {
            bodyFormData3.append("files", item.file);
          });
          const viResponse = await formPost(
            `/api/file/uploads/videos`,
            bodyFormData3
          );

          if (viResponse?.data?.length > 0) {
            viResponse.data.forEach((item) => arr.push(item.id));
          }
        }
        if (values?.publicImages?.length > 0) {
          values?.publicImages?.forEach((item) => arr.push(item.id));
        }
        if (values?.secretImages?.length > 0) {
          values?.secretImages?.forEach((item) => arr.push(item.id));
        }
        if (values?.videos?.length > 0) {
          values?.videos?.forEach((item) => arr.push(item.id));
        }
        let body = {
          userId: values.id,
          profileId: values.profileId,
          name: values.name,
          gender: values.gender,
          birthday: values.birth,
          isMarry: values.ismarry,
          height: values.height ? Number(values.height) : null,
          weight: values.weight ? Number(values.weight) : null,
          home: values.customAdressdetail,
          homeRegionCode: values.customAdressgoogun,
          parentHome: values.tureHomedetail,
          parentHomeRegionCode: values.tureHomegoogun,
          phone: values.phone,
          email: email,
          files: arr,
          recommendCode: values.recommendCode,
          profileConfirm: values.profileConfirm,
          totalScore: values.totalScore,
          matchedUser: values.matchedUser,
          appearanceScore: values.appearanceScore,
          specsScore: values.specsScore,
          wealthScore: values.wealthScore,
          comment: values.comment,
          secretComment: values.secretComment,
          joinFrom: values.joinFrom,
          drink: values.drink,
          smoke: values.smoke,
          bloodType: values.bloodType,
          tatoo: values.tatoo,
          exercise: values.sport,
          military: values.military,
          religion: values.religion,
          selfAppearance: values.myface,
          mbti: values.mbti ? values.mbti.toUpperCase() : "",
          bodyCharacter: values.bodyCharacter,
          userMarriageFee: values.userMarriageFee
            ? Number(values.userMarriageFee)
            : values.userMarriageFee,
          joinFee: values.joinFee ? Number(values.joinFee) : values.joinFee,
          counselor: values.counselor,
          marriageStartDate: values.marriageStartDate,
          marriageEndDate: values.marriageEndDate,
          reason: values.reason,
          care: values.care,
          hobby: values.myhobby,
          character: values.mystyle,
          childrenNumber: values.childrenNumber,
          drinkBottleLimit: values.drinkBottleLimit,
          drinkCupLimit: values.drinkCupLimit,
          status: "complete",
          education: {
            finalStage: values.finaledu,
            univName: values.hakname,
            univMajor: values.hakskill,
            univGradyear: values.hakenddate ? Number(values.hakenddate) : null,
            gradSchoolName: values.sukname,
            gradSchoolMajor: values.sukskill,
            gradSchoolGradyear: values.sukenddate
              ? Number(values.sukenddate)
              : null,
          },
          jobinfo: {
            job: values.jobname,
            extraJob: values.etcjobname,
            career: values.career,
            employmentType: values.jobstatus,
            workType: values.workstatus,
            yearIncome: Number(values.yearmoney.replace(/\D/g, "")),
            checkYearIncome: Number(values.Authyearmoney.replace(/\D/g, "")),
            name: values.comname,
            department: values.department,
            position: values.level,
            address: values.jobaddresdetail,
            jobRegion: values.jobaddresgoogun,
            extraComment: values.yearIncomeExtraComment,
          },
          assets: {
            movableAmount: Number(values.movemoney.replace(/\D/g, "")),
            // debt: Number(values.debt.replace(/\D/g, "")),
            checkDebt: Number(values.debt.replace(/\D/g, "")),
            checkMovableAmount: Number(values.Authmovemoney.replace(/\D/g, "")),
            immovableAmount: Number(values.nomovemoney.replace(/\D/g, "")),
            checkImmovableAmount: Number(
              values.Authnomovemoney.replace(/\D/g, "")
            ),
            hasImmovable: values.hasnomovemoney,
            assistanceExtra: Number(values.assistanceExtra.replace(/\D/g, "")),
            assistance: values.merrymoney,
            hasCar: values.hascar,
            carModelName: values.carname,
            madeBy: values.makecarname,
            extraComment: values.movableExtraComment,
            extraComment2: values.immovableExtraComment2,
          },
          familyRelation: {
            type: values.familyinfo,
            brothers: values.brothers ? Number(values.brothers) : null,
            sisters: values.sisters ? Number(values.sisters) : null,
            birthOrder: values.birthOrder ? Number(values.birthOrder) : null,
            fatherJob: values.fajob,
            isOpenFatherJob: values.isOpenFatherJob,
            motherJob: values.mojob,
            isOpenMotherJob: values.isOpenMotherJob,
            isOpenParentAsset: values.isOpenParentAsset,
            isOpenCheckParentAsset: values.isOpenCheckParentAsset,
            isOpenParentImmovableAmount: values.isOpenParentImmovableAmount,
            isOpenParentDebt: values.isOpenParentDebt,
            isOpenCheckParentDebt: values.isOpenCheckParentDebt,
            isOpenCheckParentImmovableAmount:
              values.isOpenCheckParentImmovableAmount,
            knowParentAsset: values.knowParentAsset,
            parentAsset: Number(values.pamoney.replace(/\D/g, "")),
            checkParentAsset: Number(values.Authpamoney.replace(/\D/g, "")),
            parentImmovableAmount: Number(
              values.parentImmovableAmount.replace(/\D/g, "")
            ),
            checkParentImmovableAmount: Number(
              values.AuthparentImmovableAmount.replace(/\D/g, "")
            ),
            // parentDebt: Number(values.parentDebt.replace(/\D/g, "")),
            checkParentDebt: Number(values.parentDebt.replace(/\D/g, "")),
            isLiveAlone: values.livealone,
            withParent: values.pawithlive,
            parentIndep: values.paalone,
            isSplit: values.paend,
            parentStatus: values.pasad,
            extraComment: values.parentExtraComment,
          },

          idealData: {
            birthYearMin: values.ideal_birthYearMin,
            birthYearMax: values.ideal_birthYearMax,
            heightMin: values.ideal_heightMin,
            heightMax: values.ideal_heightMax,
            education: values.ideal_education,
            appearance: values.ideal_appearance,
            industry: values.ideal_industry,
            income: values.ideal_income,
            personalAsset: values.ideal_personalAsset,
            hasImmovable: values.ideal_hasImmovable,
            parentalAsset: values.ideal_parentalAsset,
            religion: values.ideal_religion,
            allowRegion: JSON.stringify(values.ideal_allowRegion),
            character: values.ideal_caracter,
            priority: values.ideal_priority,
            hateIndustry: values.hateIndustry,
            hateMarriageHistory: values.hateMarriageHistory,
            hateSmokeType: values.hateSmokeType,
            hateTatoo: values.hateTatoo,
            isDink: values.isDink,
            hateParentStatus: values.hateParentStatus,
            hateHasCarType: values.hateHasCarType,
            idealHateDetail: values.idealHateDetail,
            hateEmploymentType: JSON.stringify(values.hateEmploymentType),
            hateInterest: values.hateInterest,
            idealDetail: values.hopeStyle ? values.hopeStyle : "",
            priority1: values.priority1,
            priority2: values.priority2,
            priority3: values.priority3,
          },
        };
        if (userstatus !== "ready") {
          body.userStatus = userstatus;
        } else {
          body.userStatus = values.status;
        }
        const response = await adminUserPut("/api/member/update", body);
        setLoading(false);
        alert("요청 성공 하였습니다.");
        window.location.reload();
        // history.goBack();
      } catch (error) {
        console.log(error);
        setLoading(false);
        if (
          error?.data?.message === "이미 2번의 기간제 휴면이 진행되었습니다."
        ) {
          if (
            window.confirm(
              "이미 2번의 기간제 휴면이 진행되었습니다.\n그래도 진행 하시겠습니까?"
            )
          ) {
            let body = {
              userId: values.id,
              profileId: values.profileId,
              name: values.name,
              gender: values.gender,
              birthday: values.birth,
              isMarry: values.ismarry,
              height: values.height ? Number(values.height) : null,
              weight: values.weight ? Number(values.weight) : null,
              home: values.customAdressdetail,
              homeRegionCode: values.customAdressgoogun,
              parentHome: values.tureHomedetail,
              parentHomeRegionCode: values.tureHomegoogun,
              phone: values.phone,
              email: email,
              files: arr,
              recommendCode: values.recommendCode,
              profileConfirm: values.profileConfirm,
              totalScore: values.totalScore,
              matchedUser: values.matchedUser,
              appearanceScore: values.appearanceScore,
              specsScore: values.specsScore,
              wealthScore: values.wealthScore,
              comment: values.comment,
              secretComment: values.secretComment,
              joinFrom: values.joinFrom,
              drink: values.drink,
              smoke: values.smoke,
              bloodType: values.bloodType,
              tatoo: values.tatoo,
              exercise: values.sport,
              military: values.military,
              religion: values.religion,
              selfAppearance: values.myface,
              mbti: values.mbti ? values.mbti.toUpperCase() : "",
              bodyCharacter: values.bodyCharacter,
              userMarriageFee: values.userMarriageFee
                ? Number(values.userMarriageFee)
                : values.userMarriageFee,
              joinFee: values.joinFee ? Number(values.joinFee) : values.joinFee,
              counselor: values.counselor,
              marriageStartDate: values.marriageStartDate,
              marriageEndDate: values.marriageEndDate,
              reason: values.reason,
              care: values.care,
              hobby: values.myhobby,
              character: values.mystyle,
              childrenNumber: values.childrenNumber,
              drinkBottleLimit: values.drinkBottleLimit,
              drinkCupLimit: values.drinkCupLimit,
              status: "complete",
              education: {
                finalStage: values.finaledu,
                univName: values.hakname,
                univMajor: values.hakskill,
                univGradyear: values.hakenddate
                  ? Number(values.hakenddate)
                  : null,
                gradSchoolName: values.sukname,
                gradSchoolMajor: values.sukskill,
                gradSchoolGradyear: values.sukenddate
                  ? Number(values.sukenddate)
                  : null,
              },
              jobinfo: {
                job: values.jobname,
                extraJob: values.etcjobname,
                career: values.career,
                employmentType: values.jobstatus,
                workType: values.workstatus,
                yearIncome: Number(values.yearmoney.replace(/\D/g, "")),
                checkYearIncome: Number(
                  values.Authyearmoney.replace(/\D/g, "")
                ),
                name: values.comname,
                department: values.department,
                position: values.level,
                address: values.jobaddresdetail,
                jobRegion: values.jobaddresgoogun,
                extraComment: values.yearIncomeExtraComment,
              },
              assets: {
                movableAmount: Number(values.movemoney.replace(/\D/g, "")),
                // debt: Number(values.debt.replace(/\D/g, "")),
                checkDebt: Number(values.debt.replace(/\D/g, "")),
                checkMovableAmount: Number(
                  values.Authmovemoney.replace(/\D/g, "")
                ),
                immovableAmount: Number(values.nomovemoney.replace(/\D/g, "")),
                checkImmovableAmount: Number(
                  values.Authnomovemoney.replace(/\D/g, "")
                ),
                hasImmovable: values.hasnomovemoney,
                assistanceExtra: Number(
                  values.assistanceExtra.replace(/\D/g, "")
                ),
                assistance: values.merrymoney,
                hasCar: values.hascar,
                carModelName: values.carname,
                madeBy: values.makecarname,
                extraComment: values.movableExtraComment,
                extraComment2: values.immovableExtraComment2,
              },
              familyRelation: {
                type: values.familyinfo,
                brothers: values.brothers ? Number(values.brothers) : null,
                sisters: values.sisters ? Number(values.sisters) : null,
                birthOrder: values.birthOrder
                  ? Number(values.birthOrder)
                  : null,
                fatherJob: values.fajob,
                isOpenFatherJob: values.isOpenFatherJob,
                motherJob: values.mojob,
                isOpenMotherJob: values.isOpenMotherJob,
                isOpenParentAsset: values.isOpenParentAsset,
                isOpenCheckParentAsset: values.isOpenCheckParentAsset,
                isOpenParentImmovableAmount: values.isOpenParentImmovableAmount,
                isOpenParentDebt: values.isOpenParentDebt,
                isOpenCheckParentDebt: values.isOpenCheckParentDebt,
                isOpenCheckParentImmovableAmount:
                  values.isOpenCheckParentImmovableAmount,
                knowParentAsset: values.knowParentAsset,
                parentAsset: Number(values.pamoney.replace(/\D/g, "")),
                checkParentAsset: Number(values.Authpamoney.replace(/\D/g, "")),
                parentImmovableAmount: Number(
                  values.parentImmovableAmount.replace(/\D/g, "")
                ),
                checkParentImmovableAmount: Number(
                  values.AuthparentImmovableAmount.replace(/\D/g, "")
                ),
                // parentDebt: Number(values.parentDebt.replace(/\D/g, "")),
                checkParentDebt: Number(values.parentDebt.replace(/\D/g, "")),
                isLiveAlone: values.livealone,
                withParent: values.pawithlive,
                parentIndep: values.paalone,
                isSplit: values.paend,
                parentStatus: values.pasad,
                extraComment: values.parentExtraComment,
              },

              idealData: {
                birthYearMin: values.ideal_birthYearMin,
                birthYearMax: values.ideal_birthYearMax,
                heightMin: values.ideal_heightMin,
                heightMax: values.ideal_heightMax,
                education: values.ideal_education,
                appearance: values.ideal_appearance,
                industry: values.ideal_industry,
                income: values.ideal_income,
                personalAsset: values.ideal_personalAsset,
                hasImmovable: values.ideal_hasImmovable,
                parentalAsset: values.ideal_parentalAsset,
                religion: values.ideal_religion,
                allowRegion: JSON.stringify(values.ideal_allowRegion),
                character: values.ideal_caracter,
                priority: values.ideal_priority,
                hateIndustry: values.hateIndustry,
                hateMarriageHistory: values.hateMarriageHistory,
                hateSmokeType: values.hateSmokeType,
                hateTatoo: values.hateTatoo,
                isDink: values.isDink,
                hateParentStatus: values.hateParentStatus,
                hateHasCarType: values.hateHasCarType,
                idealHateDetail: values.idealHateDetail,
                hateEmploymentType: JSON.stringify(values.hateEmploymentType),
                hateInterest: values.hateInterest,
                idealDetail: values.hopeStyle ? values.hopeStyle : "",
                priority1: values.priority1,
                priority2: values.priority2,
                priority3: values.priority3,
              },
              sleepForce: true,
            };
            if (userstatus !== "ready") {
              body.userStatus = userstatus;
            } else {
              body.userStatus = values.status;
            }
            const response = await adminUserPut("/api/member/update", body);
            setLoading(false);
            alert("요청 성공 하였습니다.");
            window.location.reload();
          }
        } else {
          alert(error.data.message);
        }
      }
    }
  };

  function numberToKorean(number) {
    let num = number ? number : "0";
    if (typeof number === "string") {
      num = number.replace(/\D/g, "");
    }
    const units = ["", "만", "억", "조", "경"];
    const nums = ["영", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구"];
    let result = "";
    let unit = 10000;

    if (num === 0) {
      return "영";
    }

    for (let u of units) {
      const curr = num % unit;
      num = Math.floor(num / unit);

      if (curr !== 0) {
        let currStr = "";
        const currArr = String(curr).split("").reverse();

        for (let i = 0; i < currArr.length; i++) {
          const digit = currArr[i];
          if (digit !== "0") {
            currStr =
              nums[parseInt(digit)] +
              (i > 0 ? ["", "십", "백", "천"][i] : "") +
              currStr;
          }
        }
        result = currStr + u + result;
      }

      if (num === 0) {
        break;
      }
    }

    // "일"을 제거하는 조건을 보다 구체적으로 설정하여, "일억"과 같은 경우에는 "일"을 제거하지 않음
    return (result += "원");
  }
  const calculateAge = (birthday) => {
    const birthYear = parseInt(birthday.substring(0, 4), 10);
    const birthMonth = parseInt(birthday.substring(4, 6), 10) - 1; // Month is zero-based in JavaScript Date
    const birthDay = parseInt(birthday.substring(6, 8), 10);

    const today = new Date();
    const birthDate = new Date(birthYear, birthMonth, birthDay);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    // Adjust if the birth date hasn't occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age;
  };
  const onClickIntro = async () => {
    if (values?.targetCode) {
      const response = await requestGet(
        `/api/member/check/${values?.targetCode}`
      );
      const calculatedAge = calculateAge(response.data.birthday);
      if (
        window.confirm(
          `만(${calculatedAge}세) ${response.data.name}님을 소개 하시겠습니까?`
        )
      ) {
        try {
          let body = {
            userId: id,
            targetCode: values.targetCode,
            isPending: timesent,
            sendDate: `${date} ${hour}:${minute}:00`,
          };
          await adminRequestPost("/api/introduce", body);
          alert("요청 성공 하였습니다.");
          window.location.reload();
        } catch (error) {
          if (error.data.error) {
            setIntroMsg(
              "다음과 같은 이유로 보낼 수 없습니다.\n" +
                error.data.message +
                "\n사유를 선택 하시고 강제로 보내시겠습니까?"
            );
          } else if (error.data.message) {
            setIntroMsg(
              "다음과 같은 이유로 보낼 수 없습니다.\n" +
                error.data.message +
                "\n사유를 선택 하시고 강제로 보내시겠습니까?"
            );
          }
        }
      } else {
        setValues({
          ...values,
          targetCode: "",
        });
      }
    } else {
      alert("상대의 유저코드를 입력해주세요.");
    }
  };

  const onClickSendPurchase = async (productValue) => {
    if (window.confirm("선택한 상품을 사용자에게 결제 요청하시겠습니까?")) {
      try {
        let body = {
          userId: id,
          productId: productValue,
          // isPending: timesent,
          // sendDate: `${date} ${hour}:${minute}:00`,
        };
        await adminRequestPost("/api/alarmtalk/purchase/send", body);
        alert("요청 성공 하였습니다.");
        window.location.reload();
      } catch (error) {
        console.log(error?.response);
        alert("요청 실패 하였습니다.");
      }
    }
  };

  const FrequencyEmails = [
    "@naver.com",
    "@gmail.com",
    "@daum.net",
    "@hanmail.net",
    "@yahoo.com",
    "@outlook.com",
    "@nate.com",
    "@kakao.com",
  ];
  const [email, setEmail] = useState(""); //이메일 input 값
  const [emailList, setEmailList] = useState(FrequencyEmails); //추천 이메일 리스트를 확인, 이메일 리스트 상태 관리
  const [selected, setSelected] = useState(-1); //키보드 선택
  const [isDrobBox, setIsDropbox] = useState(false); // 드롭박스 유무
  const inputRef = useRef(); //외부클릭 감지 확인

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setIsDropbox(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [inputRef]);

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    if (e.target.value.includes("@")) {
      setIsDropbox(true);
      setEmailList(
        FrequencyEmails.filter((el) =>
          el.includes(e.target.value.split("@")[1])
        )
      );
    } else {
      setIsDropbox(false);
      setSelected(-1);
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleDropDownClick = (first, second) => {
    setEmail(`${first.split("@")[0]}${second}`);
    setIsDropbox(false);
    setSelected(-1);
  };
  // const handleAddImage = (e) => {
  //   const element = e.target;

  //   const reader = new FileReader();
  //   const file = element.files && element.files[0];

  //   if (file) {
  //     loadImage(file, { meta: true, canvas: true, orientation: true }).then((img) => {

  //       (img.image).toBlob((blob) => {
  //         const rotateFile = new File([blob], file.name, { type: file.type });

  //         // reader.onloadend = (reader) => {
  //         //   handleImage &&
  //         //     handleImage([
  //         //       {
  //         //         id: 0,
  //         //         image: (reader.currentTarget).result,
  //         //         image_path: (reader.currentTarget).result,
  //         //       },
  //         //     ]);

  //         //   setUserInfo({ ...userInfo, profile: (reader.currentTarget).result });
  //         //   setIsClick({ ...isClick, profile: true });
  //         //   hadlePrintBtn({ ...isClick, profile: true });
  //         // };

  //         reader.readAsDataURL(rotateFile);
  //       }, file.type);
  //     });

  //     reader.readAsDataURL(file);
  //   }

  //   (element.value) = null;
  // };
  const handleKeyUp = (e) => {
    if (isDrobBox) {
      if (e.key === "ArrowDown" && emailList.length - 1 > selected) {
        setSelected(selected + 1);
      }
      //emailList.length에 -1을 해주는 이유는 selected의 최대값을 맞춰주기 위해서이다.
      //예를들어 밑에 emailList 2개가 나왔다고 가정했을 때, selected값이 최대 1까지 변할 수 있게 해줘야한다.
      //'ArrowDown'키를 누르면 selected는 0이 되고, 한번 더 누르면 1이 되고, 그 다음은 더이상 옵션이 없기 때문에 키가 안먹히게 해주는 것이다.

      if (e.key === "ArrowUp" && selected >= 0) {
        setSelected(selected - 1);
      }
      if (e.key === "Enter" && selected >= 0) {
        handleDropDownClick(email, emailList[selected]);
      }
    }
  };

  const handlePhoneChange = (e) => {
    const regex = /^[0-9+-]*$/;

    if (regex.test(e.target.value)) {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
    }
  };

  const callAlarmtalk = async () => {
    let body = {
      phone: testphone,
      name: testname,
      disablePhone: testdisphone,
    };
    await adminRequestPost(`/api/alarmtalk/test/send`, body);
  };

  const handleBookmarkClick = (targetId) => {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const elementPosition =
        targetElement.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - 60;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };
  function SelectFn({
    name,
    keyProp,
    values,
    value,
    key,
    type,
    handleChange,
    handleChangeBoolean,
    handleCheck,
    handleCheckstr,
    handleMoneyChange,
  }) {
    if (type === "text") {
      if (keyProp === "matchedUser") {
        if (values?.status === "matched") {
          return (
            <Row className="rowBorderBottom pt-3 pb-3" key={key}>
              <Col lg={12} className="d-flex flex-column">
                <div>
                  <label htmlFor={`${keyProp}Label`} className="labelText">
                    {name}
                  </label>
                  <input
                    type="text"
                    value={values?.[keyProp]}
                    className="js-input-mask form-control customInput"
                    name={keyProp}
                    id={`${keyProp}Label`}
                    placeholder={`${name}을(를) 입력해 주세요.`}
                    aria-label={`${name}을(를) 입력해 주세요.`}
                    onChange={handleChange}
                  />
                </div>
              </Col>
            </Row>
          );
        }
      } else if (keyProp === "name") {
        return (
          <Row className="rowBorderBottom pt-3 pb-3" key={key}>
            <Col lg={12} className="d-flex flex-column">
              <div style={{ marginBottom: "10px" }}>
                <label htmlFor={`${keyProp}Label`} className="labelText">
                  {name}
                </label>
                <input
                  type="text"
                  value={values?.[keyProp]}
                  className="js-input-mask form-control customInput"
                  name={keyProp}
                  id={`${keyProp}Label`}
                  placeholder={`${name}을(를) 입력해 주세요.`}
                  aria-label={`${name}을(를) 입력해 주세요.`}
                  onChange={handleChange}
                />
              </div>
              {values.userCode ? (
                <div>
                  <span>userCode : </span>
                  <span>{values.userCode}</span>
                  <Paste
                    width={20}
                    onClick={() => handleCopyToClipboard(values.userCode)}
                    style={{
                      marginLeft: "5px",
                      cursor: "pointer",
                    }}
                  />
                  <div>
                    첫 소개일로부터 {values.dayCountingAfterIntroduce}일
                  </div>
                  <div>{values.membershipStartDate ? "기간제 회원" : ""}</div>
                </div>
              ) : null}
            </Col>
          </Row>
        );
      } else if (keyProp === "fajob") {
        return (
          <Row className="rowBorderBottom pt-3 pb-3" key={key}>
            <Col lg={12} className="d-flex flex-column">
              <div>
                <label htmlFor={`${keyProp}Label`} className="labelText">
                  {name}
                </label>
                <div className="d-flex radiowrap">
                  <div className="d-flex mr20 mb-2 align-items-center">
                    <input
                      type="radio"
                      name={"isOpenFatherJob"}
                      id={`isOpenFatherJobYesLabel`}
                      value={true}
                      checked={values?.isOpenFatherJob === true}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                      onClick={(e) => handleChangeBoolean(e)}
                    />
                    <label
                      htmlFor={`isOpenFatherJobYesLabel`}
                      className="textLabel m-0"
                    >
                      공개
                    </label>
                  </div>
                  <div className="d-flex mr20 mb-2 align-items-center">
                    <input
                      type="radio"
                      name={"isOpenFatherJob"}
                      id={`isOpenFatherJobNoLabel`}
                      value={false}
                      checked={values?.isOpenFatherJob === false}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                      onClick={(e) => handleChangeBoolean(e)}
                    />
                    <label
                      htmlFor={`isOpenFatherJobNoLabel`}
                      className="textLabel m-0"
                    >
                      비공개
                    </label>
                  </div>
                </div>
                <input
                  type="text"
                  value={values?.[keyProp]}
                  className="js-input-mask form-control customInput"
                  name={keyProp}
                  id={`${keyProp}Label`}
                  placeholder={`${name}을(를) 입력해 주세요.`}
                  aria-label={`${name}을(를) 입력해 주세요.`}
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>
        );
      } else if (keyProp === "mojob") {
        return (
          <Row className="rowBorderBottom pt-3 pb-3" key={key}>
            <Col lg={12} className="d-flex flex-column">
              <div>
                <label htmlFor={`${keyProp}Label`} className="labelText">
                  {name}
                </label>
                <div className="d-flex radiowrap">
                  <div className="d-flex mr20 mb-2 align-items-center">
                    <input
                      type="radio"
                      name={"isOpenMotherJob"}
                      id={`isOpenMotherJobYesLabel`}
                      value={true}
                      checked={values?.isOpenMotherJob === true}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                      onClick={(e) => handleChangeBoolean(e)}
                    />
                    <label
                      htmlFor={`isOpenMotherJobYesLabel`}
                      className="textLabel m-0"
                    >
                      공개
                    </label>
                  </div>
                  <div className="d-flex mr20 mb-2 align-items-center">
                    <input
                      type="radio"
                      name={"isOpenMotherJob"}
                      id={`isOpenMotherJobNoLabel`}
                      value={false}
                      checked={values?.isOpenMotherJob === false}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                      onClick={(e) => handleChangeBoolean(e)}
                    />
                    <label
                      htmlFor={`isOpenMotherJobNoLabel`}
                      className="textLabel m-0"
                    >
                      비공개
                    </label>
                  </div>
                </div>
                <input
                  type="text"
                  value={values?.[keyProp]}
                  className="js-input-mask form-control customInput"
                  name={keyProp}
                  id={`${keyProp}Label`}
                  placeholder={`${name}을(를) 입력해 주세요.`}
                  aria-label={`${name}을(를) 입력해 주세요.`}
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>
        );
      } else if (keyProp === "joinFrom") {
        return (
          <Row className="rowBorderBottom pt-3 pb-3">
            <Col lg={12} className="d-flex flex-column">
              <div>
                <label htmlFor={`${keyProp}Label`} className="labelText">
                  {name}
                </label>
                <select
                  className="form-select customInput"
                  name="joinFrom"
                  value={values?.joinFromrecommand}
                  onChange={handleChangerecommand}
                  style={
                    !values.joinFromrecommand
                      ? {
                          color: "#BCBCBC",
                        }
                      : { color: "#333" }
                  }
                >
                  {!values.joinFromrecommand ? (
                    <option>가입 경로를 선택해 주세요.</option>
                  ) : null}
                  {joinFromData?.map((item, i) => {
                    return (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    );
                  })}
                  <option value={"기타"}>기타</option>
                </select>
                {values?.joinFromrecommand === "기타" ? (
                  <input
                    type="text"
                    className="js-input-mask form-control customInput"
                    name="joinFrom"
                    id="joinFromLabel"
                    value={values?.joinFrom}
                    placeholder="가입 경로를 입력해 주세요."
                    aria-label="가입 경로를 입력해 주세요."
                    onChange={handleChange}
                    style={{ marginTop: "20px" }}
                  />
                ) : null}
              </div>
            </Col>
          </Row>
        );
      } else {
        return (
          <Row className="rowBorderBottom pt-3 pb-3" key={key}>
            <Col lg={12} className="d-flex flex-column">
              <div>
                <label htmlFor={`${keyProp}Label`} className="labelText">
                  {name}
                </label>
                <input
                  type="text"
                  value={values?.[keyProp]}
                  className="js-input-mask form-control customInput"
                  name={keyProp}
                  id={`${keyProp}Label`}
                  placeholder={`${name}을(를) 입력해 주세요.`}
                  aria-label={`${name}을(를) 입력해 주세요.`}
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>
        );
      }
    }
    if (type === "phone") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3" key={key}>
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <input
                type="text"
                value={values?.[keyProp]}
                className="js-input-mask form-control customInput"
                name={keyProp}
                id={`${keyProp}Label`}
                placeholder={`${name}을(를) 입력해 주세요.`}
                aria-label={`${name}을(를) 입력해 주세요.`}
                onChange={handlePhoneChange}
              />
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "email") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3" key={key}>
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <div ref={inputRef} style={{ position: "relative" }}>
                <input
                  type="email"
                  name={values?.[email]}
                  placeholder={`${name}을(를) 입력해 주세요.`}
                  aria-label={`${name}을(를) 입력해 주세요.`}
                  className="js-input-mask form-control customInput"
                  value={email}
                  onChange={(e) => {
                    onChangeEmail(e);
                  }}
                  onKeyUp={handleKeyUp}
                />
                {isDrobBox && (
                  <MailTipUl>
                    {emailList.map((item, idx) => (
                      <MailTipLi
                        key={idx}
                        onMouseOver={() => setSelected(idx)}
                        onClick={() => handleDropDownClick(email, item)}
                        selected={selected === idx}
                      >
                        {email.split("@")[0]}
                        {item}
                      </MailTipLi>
                    ))}
                  </MailTipUl>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "money") {
      if (keyProp === "yearmoney") {
        return (
          <Row className="rowBorderBottom pt-3 pb-3" key={key}>
            <Col lg={12} className="d-flex flex-column">
              <div>
                <label htmlFor={`${keyProp}Label`} className="labelText">
                  {name}
                </label>
                <input
                  type="text"
                  value={values?.[keyProp]}
                  className="js-input-mask form-control customInput"
                  name={keyProp}
                  id={`${keyProp}Label`}
                  placeholder={`${name}을(를) 입력해 주세요.`}
                  aria-label={`${name}을(를) 입력해 주세요.`}
                  onChange={handleMoneyChange}
                />
                <div>{numberToKorean(values?.[keyProp])}</div>
              </div>
            </Col>
            <Col lg={12} className="d-flex flex-column">
              <div>
                <label htmlFor={`${keyProp}Label`} className="labelText">
                  인증 {name}
                </label>
                <input
                  type="text"
                  value={values?.[`Auth${keyProp}`]}
                  className="js-input-mask form-control customInput"
                  name={`Auth${keyProp}`}
                  id={`${keyProp}Label`}
                  placeholder={`인증된 금액을 입력해 주세요.`}
                  aria-label={`인증된 금액을 입력해 주세요.`}
                  onChange={handleMoneyChange}
                />
                <div>{numberToKorean(values?.[`Auth${keyProp}`])}</div>
              </div>
            </Col>
            <Col lg={12} className="d-flex flex-column">
              <div>
                <label
                  htmlFor={`yearIncomeExtraCommentLabel`}
                  className="labelText"
                >
                  연봉 비고
                </label>
                <input
                  type="text"
                  value={values?.yearIncomeExtraComment}
                  className="js-input-mask form-control customInput"
                  name={`yearIncomeExtraComment`}
                  id={`yearIncomeExtraCommentLabel`}
                  placeholder={`연봉 비고를 입력해 주세요.`}
                  aria-label={`연봉 비고를 입력해 주세요.`}
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>
        );
      } else if (keyProp === "pamoney") {
        return (
          <>
            <Row className="rowBorderBottom pt-3 pb-3" key={key}>
              <Col lg={12} className="d-flex flex-column">
                <div>
                  <label htmlFor={`${keyProp}Label`} className="labelText">
                    부모님 자산
                  </label>
                  <div
                    className="d-flex radiowrap"
                    style={{ marginBottom: "10px" }}
                  >
                    <div className="d-flex mr20 mb-2 align-items-center">
                      <input
                        type="radio"
                        name={"knowParentAsset"}
                        id={`knowParentAssetYesLabel`}
                        value={"알고있음"}
                        checked={values?.knowParentAsset === "알고있음"}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                        onClick={(e) => handleChange(e)}
                      />
                      <label
                        htmlFor={`knowParentAssetYesLabel`}
                        className="textLabel m-0"
                      >
                        알고있음
                      </label>
                    </div>
                    <div className="d-flex mr20 mb-2 align-items-center">
                      <input
                        type="radio"
                        name={"knowParentAsset"}
                        id={`knowParentAssetNoLabel`}
                        value={"모름"}
                        checked={values?.knowParentAsset === "모름"}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                        onClick={(e) => handleChange(e)}
                      />
                      <label
                        htmlFor={`knowParentAssetNoLabel`}
                        className="textLabel m-0"
                      >
                        모름
                      </label>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={12} className="d-flex flex-column">
                <div>
                  <div className="d-flex radiowrap">
                    <div className="d-flex mr20 mb-2 align-items-center">
                      <input
                        type="radio"
                        name={"isOpenParentAsset"}
                        id={`isOpenParentAssetYesLabel`}
                        value={true}
                        checked={values?.isOpenParentAsset === true}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                        onClick={(e) => handleChangeBoolean(e)}
                      />
                      <label
                        htmlFor={`isOpenParentAssetYesLabel`}
                        className="textLabel m-0"
                      >
                        공개
                      </label>
                    </div>
                    <div className="d-flex mr20 mb-2 align-items-center">
                      <input
                        type="radio"
                        name={"isOpenParentAsset"}
                        id={`isOpenParentAssetNoLabel`}
                        value={false}
                        checked={values?.isOpenParentAsset === false}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                        onClick={(e) => handleChangeBoolean(e)}
                      />
                      <label
                        htmlFor={`isOpenParentAssetNoLabel`}
                        className="textLabel m-0"
                      >
                        비공개
                      </label>
                    </div>
                  </div>
                  <input
                    type="text"
                    value={values?.[keyProp]}
                    className="js-input-mask form-control customInput"
                    name={keyProp}
                    id={`${keyProp}Label`}
                    placeholder={`${name}을(를) 입력해 주세요.`}
                    aria-label={`${name}을(를) 입력해 주세요.`}
                    onChange={handleMoneyChange}
                  />
                  <div>{numberToKorean(values?.[keyProp])}</div>
                </div>
              </Col>
              <Col lg={12} className="d-flex flex-column">
                <div>
                  <label htmlFor={`${keyProp}Label`} className="labelText">
                    인증 {name}
                  </label>
                  <div className="d-flex radiowrap">
                    <div className="d-flex mr20 mb-2 align-items-center">
                      <input
                        type="radio"
                        name={"isOpenCheckParentAsset"}
                        id={`isOpenCheckParentAssetYesLabel`}
                        value={true}
                        checked={values?.isOpenCheckParentAsset === true}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                        onClick={(e) => handleChangeBoolean(e)}
                      />
                      <label
                        htmlFor={`isOpenCheckParentAssetYesLabel`}
                        className="textLabel m-0"
                      >
                        공개
                      </label>
                    </div>
                    <div className="d-flex mr20 mb-2 align-items-center">
                      <input
                        type="radio"
                        name={"isOpenCheckParentAsset"}
                        id={`isOpenCheckParentAssetNoLabel`}
                        value={false}
                        checked={values?.isOpenCheckParentAsset === false}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                        onClick={(e) => handleChangeBoolean(e)}
                      />
                      <label
                        htmlFor={`isOpenCheckParentAssetNoLabel`}
                        className="textLabel m-0"
                      >
                        비공개
                      </label>
                    </div>
                  </div>
                  <input
                    type="text"
                    value={values?.[`Auth${keyProp}`]}
                    className="js-input-mask form-control customInput"
                    name={`Auth${keyProp}`}
                    id={`${keyProp}Label`}
                    placeholder={`인증된 금액을 입력해 주세요.`}
                    aria-label={`인증된 금액을 입력해 주세요.`}
                    onChange={handleMoneyChange}
                  />
                  <div>{numberToKorean(values?.[`Auth${keyProp}`])}</div>
                </div>
              </Col>
              <Col lg={12} className="d-flex flex-column">
                <div>
                  <label
                    htmlFor={`parentExtraCommentLabel`}
                    className="labelText"
                  >
                    부모님 자산 비고
                  </label>
                  <input
                    type="text"
                    value={values?.parentExtraComment}
                    className="js-input-mask form-control customInput"
                    name={`parentExtraComment`}
                    id={`parentExtraCommentLabel`}
                    placeholder={`부모님 자산 비고를 입력해 주세요.`}
                    aria-label={`부모님 자산 비고를 입력해 주세요.`}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={12} className="d-flex flex-column">
                <div>
                  <label htmlFor={`parentDebtLabel`} className="labelText">
                    인증 부모님 부채자산
                  </label>
                  <div className="d-flex radiowrap">
                    <div className="d-flex mr20 mb-2 align-items-center">
                      <input
                        type="radio"
                        name={"isOpenCheckParentDebt"}
                        id={`isOpenCheckParentDebtYesLabel`}
                        value={true}
                        checked={values?.isOpenCheckParentDebt === true}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                        onClick={(e) => handleChangeBoolean(e)}
                      />
                      <label
                        htmlFor={`isOpenCheckParentDebtYesLabel`}
                        className="textLabel m-0"
                      >
                        공개
                      </label>
                    </div>
                    <div className="d-flex mr20 mb-2 align-items-center">
                      <input
                        type="radio"
                        name={"isOpenCheckParentDebt"}
                        id={`isOpenCheckParentDebtNoLabel`}
                        value={false}
                        checked={values?.isOpenCheckParentDebt === false}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                        onClick={(e) => handleChangeBoolean(e)}
                      />
                      <label
                        htmlFor={`isOpenCheckParentDebtNoLabel`}
                        className="textLabel m-0"
                      >
                        비공개
                      </label>
                    </div>
                  </div>
                  <input
                    type="text"
                    value={values?.parentDebt}
                    className="js-input-mask form-control customInput"
                    name={`parentDebt`}
                    id={`parentDebtLabel`}
                    placeholder={`인증된 금액을 입력해 주세요.`}
                    aria-label={`인증된 금액을 입력해 주세요.`}
                    onChange={handleMoneyChange}
                  />
                  <div>{numberToKorean(values?.parentDebt)}</div>
                </div>
              </Col>
            </Row>
          </>
        );
      } else if (keyProp === "nomovemoney") {
        return (
          <>
            <Row className="rowBorderBottom pt-3 pb-3" key={key}>
              <Col lg={12} className="d-flex flex-column">
                <div>
                  <label htmlFor={`${keyProp}Label`} className="labelText">
                    {name}
                  </label>
                  <input
                    type="text"
                    value={values?.[keyProp]}
                    className="js-input-mask form-control customInput"
                    name={keyProp}
                    id={`${keyProp}Label`}
                    placeholder={`${name}을(를) 입력해 주세요.`}
                    aria-label={`${name}을(를) 입력해 주세요.`}
                    onChange={handleMoneyChange}
                  />
                  <div>{numberToKorean(values?.[keyProp])}</div>
                </div>
              </Col>
              <Col lg={12} className="d-flex flex-column">
                <div>
                  <label htmlFor={`${keyProp}Label`} className="labelText">
                    인증 {name}
                  </label>
                  <input
                    type="text"
                    value={values?.[`Auth${keyProp}`]}
                    className="js-input-mask form-control customInput"
                    name={`Auth${keyProp}`}
                    id={`${keyProp}Label`}
                    placeholder={`인증된 금액을 입력해 주세요.`}
                    aria-label={`인증된 금액을 입력해 주세요.`}
                    onChange={handleMoneyChange}
                  />
                  <div>{numberToKorean(values?.[`Auth${keyProp}`])}</div>
                </div>
              </Col>
              <Col lg={12} className="d-flex flex-column">
                <div>
                  <label htmlFor={`debtLabel`} className="labelText">
                    인증 부채자산
                  </label>
                  <input
                    type="text"
                    value={values?.debt}
                    className="js-input-mask form-control customInput"
                    name={`debt`}
                    id={`debtLabel`}
                    placeholder={`인증된 금액을 입력해 주세요.`}
                    aria-label={`인증된 금액을 입력해 주세요.`}
                    onChange={handleMoneyChange}
                  />
                  <div>{numberToKorean(values?.debt)}</div>
                </div>
              </Col>
              <Col lg={12} className="d-flex flex-column">
                <div>
                  <label
                    htmlFor={`immovableExtraComment2Label`}
                    className="labelText"
                  >
                    비유동자산(부동산 포함) 비고
                  </label>
                  <input
                    type="text"
                    value={values?.immovableExtraComment2}
                    className="js-input-mask form-control customInput"
                    name={`immovableExtraComment2`}
                    id={`immovableExtraComment2Label`}
                    placeholder={`비유동자산(부동산 포함) 비고를 입력해 주세요.`}
                    aria-label={`비유동자산(부동산 포함) 비고를 입력해 주세요.`}
                    onChange={handleChange}
                  />
                </div>
              </Col>
            </Row>
          </>
        );
      } else if (keyProp === "movemoney") {
        return (
          <>
            <Row className="rowBorderBottom pt-3 pb-3" key={key}>
              <Col lg={12} className="d-flex flex-column">
                <div>
                  <label htmlFor={`${keyProp}Label`} className="labelText">
                    {name}
                  </label>
                  <input
                    type="text"
                    value={values?.[keyProp]}
                    className="js-input-mask form-control customInput"
                    name={keyProp}
                    id={`${keyProp}Label`}
                    placeholder={`${name}을(를) 입력해 주세요.`}
                    aria-label={`${name}을(를) 입력해 주세요.`}
                    onChange={handleMoneyChange}
                  />
                  <div>{numberToKorean(values?.[keyProp])}</div>
                </div>
              </Col>
              <Col lg={12} className="d-flex flex-column">
                <div>
                  <label htmlFor={`${keyProp}Label`} className="labelText">
                    인증 {name}
                  </label>
                  <input
                    type="text"
                    value={values?.[`Auth${keyProp}`]}
                    className="js-input-mask form-control customInput"
                    name={`Auth${keyProp}`}
                    id={`${keyProp}Label`}
                    placeholder={`인증된 금액을 입력해 주세요.`}
                    aria-label={`인증된 금액을 입력해 주세요.`}
                    onChange={handleMoneyChange}
                  />
                  <div>{numberToKorean(values?.[`Auth${keyProp}`])}</div>
                </div>
              </Col>
              <Col lg={12} className="d-flex flex-column">
                <div>
                  <label
                    htmlFor={`movableExtraCommentLabel`}
                    className="labelText"
                  >
                    유동자산 비고
                  </label>
                  <input
                    type="text"
                    value={values?.movableExtraComment}
                    className="js-input-mask form-control customInput"
                    name={`movableExtraComment`}
                    id={`movableExtraCommentLabel`}
                    placeholder={`유동자산 비고를 입력해 주세요.`}
                    aria-label={`유동자산 비고를 입력해 주세요.`}
                    onChange={handleChange}
                  />
                </div>
              </Col>
            </Row>
          </>
        );
      } else {
        return (
          <Row className="rowBorderBottom pt-3 pb-3" key={key}>
            <Col lg={12} className="d-flex flex-column">
              <div>
                <label htmlFor={`${keyProp}Label`} className="labelText">
                  {name}
                </label>
                <input
                  type="text"
                  value={values?.[keyProp]}
                  className="js-input-mask form-control customInput"
                  name={keyProp}
                  id={`${keyProp}Label`}
                  placeholder={`${name}을(를) 입력해 주세요.`}
                  aria-label={`${name}을(를) 입력해 주세요.`}
                  onChange={handleMoneyChange}
                />
                <div>{numberToKorean(values?.[keyProp])}</div>
              </div>
            </Col>
            <Col lg={12} className="d-flex flex-column">
              <div>
                <label htmlFor={`${keyProp}Label`} className="labelText">
                  인증 {name}
                </label>
                <input
                  type="text"
                  value={values?.[`Auth${keyProp}`]}
                  className="js-input-mask form-control customInput"
                  name={`Auth${keyProp}`}
                  id={`${keyProp}Label`}
                  placeholder={`인증된 금액을 입력해 주세요.`}
                  aria-label={`인증된 금액을 입력해 주세요.`}
                  onChange={handleMoneyChange}
                />
                <div>{numberToKorean(values?.[`Auth${keyProp}`])}</div>
              </div>
            </Col>
          </Row>
        );
      }
    }
    if (type === "number") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3" key={key}>
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <input
                type="number"
                value={values?.[keyProp]}
                className="js-input-mask form-control customInput"
                name={keyProp}
                id={`${keyProp}Label`}
                onWheel={(e) => e.target.blur()}
                placeholder={`${name}을(를) 입력해 주세요.`}
                aria-label={`${name}을(를) 입력해 주세요.`}
                onChange={handleChange}
              />
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "intro") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3" key={key}>
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <input
                type="text"
                value={values?.[keyProp]}
                className="js-input-mask form-control customInput"
                name={keyProp}
                id={`${keyProp}Label`}
                placeholder={`유저코드을(를) 입력해 주세요.`}
                aria-label={`유저코드을(를) 입력해 주세요.`}
                onChange={handleChange}
              />
            </div>
          </Col>
          <Col lg={12} className="d-flex flex-column">
            <div className="d-flex align-items-center mt-2">
              <input
                type="checkbox"
                name={keyProp}
                value={timesent}
                id="introLabel"
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "5px",
                }}
                onClick={() => setTimesent(!timesent)}
              />
              <label htmlFor={`introLabel`} className="labelText m-0">
                예약 발송
              </label>
            </div>
          </Col>
          {timesent ? (
            <Col lg={12} className="d-flex flex-column">
              <div>
                <Col lg={5} className="d-flex flex-column">
                  <div>
                    <div className="input-group">
                      <input
                        type="date"
                        className="js-input-mask form-control customInputLeft"
                        name="firstDate"
                        id="firstDateLabel"
                        min={moment().format("YYYY-MM-DD")}
                        max="9999-12-31"
                        value={date}
                        onChange={(e) =>
                          setDate(moment(e.target.value).format("YYYY-MM-DD"))
                        }
                        style={{ maxWidth: "9rem" }}
                      />
                      <select
                        className="form-select customInputRight"
                        name="firstDateTime"
                        style={{
                          maxWidth: "4rem",
                        }}
                        value={hour}
                        onChange={(e) => setHour(e.target.value)}
                      >
                        <option value="09">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                      </select>
                      <select
                        className="form-select customInputRight"
                        name="firstDateTime"
                        style={{
                          maxWidth: "4rem",
                        }}
                        value={minute}
                        onChange={(e) => setMinute(e.target.value)}
                      >
                        <option value="00">00</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                      </select>
                    </div>
                  </div>
                </Col>
              </div>
            </Col>
          ) : null}
          <Col lg={12} className="d-flex flex-column">
            <div>
              <Button
                color="primary"
                style={{ marginTop: "6px", marginRight: "10px" }}
                onClick={() => onClickIntro()}
              >
                소개하기
              </Button>
              D-day : {values?.dDay}
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "messageAlarmtalk") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3" key={key}>
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label className="labelText">Phone</label>
              <input
                type="text"
                value={testphone}
                className="js-input-mask form-control customInput"
                onChange={(e) => setTestphone(e.target.value)}
              />
            </div>
          </Col>
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label className="labelText">Disabled Phone</label>
              <input
                type="text"
                value={testdisphone}
                className="js-input-mask form-control customInput"
                onChange={(e) => setTestdisphone(e.target.value)}
              />
            </div>
          </Col>
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label className="labelText">name</label>
              <input
                type="text"
                value={testname}
                className="js-input-mask form-control customInput"
                onChange={(e) => setTestName(e.target.value)}
              />
            </div>
          </Col>

          <Col lg={12} className="d-flex flex-column">
            <div>
              <Button
                color="primary"
                style={{ marginTop: "6px", marginRight: "10px" }}
                onClick={() => callAlarmtalk()}
              >
                알림톡 보내기
              </Button>
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "textarea") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3" key={key}>
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <textarea
                className="js-input-mask form-control customInput"
                name={keyProp}
                value={values?.[keyProp]}
                id={`${keyProp}Label`}
                placeholder={`${name}을(를) 입력해 주세요.`}
                aria-label={`${name}을(를) 입력해 주세요.`}
                onChange={handleChange}
                style={{ height: "300px", resize: "none" }}
              />
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "date") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3" key={key}>
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <input
                type="date"
                className="js-input-mask form-control customInputLeft"
                name={keyProp}
                value={values?.[keyProp]}
                id={`${keyProp}Label`}
                max="9999-12-31"
                onChange={handleChange}
                style={
                  !values?.[keyProp]
                    ? {
                        color: "#BCBCBC",
                      }
                    : { color: "#333" }
                }
              />
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "select") {
      if (keyProp === "userMarriageFee") {
        return (
          <Row className="rowBorderBottom pt-3 pb-3" key={key}>
            <Col lg={12} className="d-flex flex-column">
              <div>
                <label htmlFor={`${keyProp}Label`} className="labelText">
                  {name}
                </label>
                <select
                  id="counselor_genderLabel"
                  className="form-select customInput"
                  name={keyProp}
                  value={values?.[keyProp]}
                  onChange={handleChange}
                  style={
                    !values[keyProp]
                      ? {
                          color: "#BCBCBC",
                        }
                      : { color: "#333" }
                  }
                >
                  {!values?.[keyProp] ? (
                    <option>{`${name}을(를) 선택해 주세요.`}</option>
                  ) : null}
                  {marriagefeedata?.map((item, i) => {
                    return (
                      <option value={item.id} key={i}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </Col>
          </Row>
        );
      }
      if (keyProp === "joinFee") {
        return (
          <Row className="rowBorderBottom pt-3 pb-3" key={key}>
            <Col lg={12} className="d-flex flex-column">
              <div>
                <label htmlFor={`${keyProp}Label`} className="labelText">
                  {name}
                </label>
                <select
                  id="counselor_genderLabel"
                  className="form-select customInput"
                  name={keyProp}
                  value={values?.[keyProp]}
                  onChange={handleChange}
                  style={
                    !values[keyProp]
                      ? {
                          color: "#BCBCBC",
                        }
                      : { color: "#333" }
                  }
                >
                  {!values?.[keyProp] ? (
                    <option>{`${name}을(를) 선택해 주세요.`}</option>
                  ) : null}
                  {joinfeedata?.map((item, i) => {
                    return (
                      <option value={item.id} key={i}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </Col>
          </Row>
        );
      } else if (keyProp === "counselor") {
        return (
          <Row className="rowBorderBottom pt-3 pb-3" key={key}>
            <Col lg={12} className="d-flex flex-column">
              <div>
                <label htmlFor={`${keyProp}Label`} className="labelText">
                  {name}
                </label>
                <select
                  id="counselor_genderLabel"
                  className="form-select customInput"
                  name={keyProp}
                  value={values?.[keyProp]}
                  onChange={handleChange}
                  style={
                    !values[keyProp]
                      ? {
                          color: "#BCBCBC",
                        }
                      : { color: "#333" }
                  }
                >
                  {!values?.[keyProp] ? (
                    <option>{`${name}을(를) 선택해 주세요.`}</option>
                  ) : null}
                  {managers?.map((item, i) => {
                    return (
                      <option value={item.name} key={i}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </Col>
          </Row>
        );
      } else if (keyProp === "status") {
        return (
          <Row className="rowBorderBottom pt-3 pb-3" key={key}>
            <Col lg={12} className="d-flex flex-column">
              <div>
                <label htmlFor={`${keyProp}Label`} className="labelText">
                  {name}
                </label>
                <select
                  id="counselor_genderLabel"
                  className="form-select customInput"
                  name={keyProp}
                  value={values?.[keyProp]}
                  // disabled={authUser !== "auth"}
                  onChange={handleChange}
                  style={
                    !values[keyProp]
                      ? {
                          color: "#BCBCBC",
                        }
                      : { color: "#333" }
                  }
                >
                  {values?.[keyProp] !== "active" &&
                  values?.[keyProp] !== "sleep" &&
                  values?.[keyProp] !== "matched" ? (
                    <option>{`${name}을(를) 선택해 주세요.`}</option>
                  ) : null}
                  {value?.map((item, i) => {
                    return (
                      <option value={item} key={i}>
                        {item === "active"
                          ? "정상"
                          : item === "sleep"
                          ? "휴면"
                          : item === "matched"
                          ? "교제중"
                          : ""}
                      </option>
                    );
                  })}
                </select>
              </div>
            </Col>
          </Row>
        );
      } else {
        return (
          <Row className="rowBorderBottom pt-3 pb-3" key={key}>
            <Col lg={12} className="d-flex flex-column">
              <div>
                <label htmlFor={`${keyProp}Label`} className="labelText">
                  {name}
                </label>
                <select
                  id="counselor_genderLabel"
                  className="form-select customInput"
                  name={keyProp}
                  value={values?.[keyProp]}
                  onChange={handleChange}
                  style={
                    !values[keyProp]
                      ? {
                          color: "#BCBCBC",
                        }
                      : { color: "#333" }
                  }
                >
                  {!values?.[keyProp] ? (
                    <option>{`${name}을(를) 선택해 주세요.`}</option>
                  ) : null}
                  {value.map((item, i) => {
                    return (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
            </Col>
          </Row>
        );
      }
    }
    if (type === "location") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3" key={key}>
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <Row>
                <Col>시/도</Col>
              </Row>
              <select
                id="counselor_genderLabel"
                className="form-select customInput"
                name={keyProp}
                value={values?.[keyProp]}
                onChange={handleSidoChange}
                style={
                  !values[keyProp]
                    ? {
                        color: "#BCBCBC",
                      }
                    : { color: "#333" }
                }
              >
                {!values?.[keyProp] ? (
                  <option>{`${name}을(를) 선택해 주세요.`}</option>
                ) : null}
                {locationData.map((item, i) => {
                  return (
                    <option value={item.name} key={i}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              <Row>
                <Col>시/구/군</Col>
              </Row>
              <select
                id="counselor_genderLabel"
                className="form-select customInput"
                name={`${keyProp}googun`}
                value={values?.[`${keyProp}googun`]}
                onChange={handleChange}
                style={
                  !values?.[`${keyProp}googun`]
                    ? {
                        color: "#BCBCBC",
                      }
                    : { color: "#333" }
                }
              >
                {!values?.[`${keyProp}googun`] ? (
                  <option>{`${name}을(를) 선택해 주세요.`}</option>
                ) : null}
                {locationData.map((item, i) => {
                  let str =
                    keyProp === "customAdress"
                      ? sido
                      : keyProp === "jobaddres"
                      ? sidoJob
                      : sidoTrue;
                  if (item?.name === str) {
                    return item.list.map((el, j) => {
                      return (
                        <option value={el.regionCode} key={j}>
                          {el.name}
                        </option>
                      );
                    });
                  }
                })}
              </select>
              <div>
                <Row>
                  <Col>상세입력</Col>
                </Row>
                <input
                  type="text"
                  value={values?.[`${keyProp}detail`]}
                  className="js-input-mask form-control customInput"
                  name={`${keyProp}detail`}
                  id={`${keyProp}detailLabel`}
                  placeholder={`${name}을(를) 입력해 주세요.`}
                  aria-label={`${name}을(를) 입력해 주세요.`}
                  onChange={handleChange}
                />
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "brothers") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3" key={key}>
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`brothersLabel`} className="labelText">
                {name}
              </label>
              <div className="d-flex align-items-center">
                <input
                  type="number"
                  className="js-input-mask form-control customInput"
                  name="brothers"
                  id={`brothersLabel`}
                  value={values["brothers"]}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleChange}
                  style={{
                    width: "44px",
                    padding: "0.47rem 0px 0.47rem 0.75rem",
                    textAlign: "end",
                  }}
                />
                <label className="textLabel mx-2 my-0">남</label>
                <input
                  type="number"
                  className="js-input-mask form-control customInput"
                  name="sisters"
                  id={`sistersLabel`}
                  value={values["sisters"]}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleChange}
                  style={{
                    width: "44px",
                    padding: "0.47rem 0px 0.47rem 0.75rem",
                    textAlign: "end",
                  }}
                />
                <label className="textLabel mx-2 my-0">녀</label>
                <input
                  type="number"
                  className="js-input-mask form-control customInput"
                  name="birthOrder"
                  id={`birthOrderLabel`}
                  value={values["birthOrder"]}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleChange}
                  style={{
                    width: "44px",
                    padding: "0.47rem 0px 0.47rem 0.75rem",
                    textAlign: "end",
                  }}
                />
                <label className="textLabel mx-2 my-0">째</label>
              </div>
            </div>
          </Col>
        </Row>
      );
    }

    if (type === "etc") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3" key={key}>
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <div className="d-flex pt-2 radiowrap">
                {value?.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex mr20 mb-2 align-items-center"
                  >
                    <input
                      type="radio"
                      name={keyProp}
                      id={`${keyProp}${item}Label`}
                      value={item}
                      checked={values?.[keyProp] === item}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                      onClick={(e) => handleChangeOriginalRadio(e)}
                    />
                    <label
                      htmlFor={`${keyProp}${item}Label`}
                      className="textLabel m-0"
                    >
                      {item}
                    </label>
                  </div>
                ))}
                <div className="d-flex mr20 mb-2 align-items-center">
                  <input
                    type="radio"
                    value="기타"
                    id={`${keyProp}기타Label`}
                    name={keyProp}
                    checked={
                      values?.[keyProp]
                        ? !value.includes(values?.[keyProp])
                          ? true
                          : false
                        : false
                    }
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "5px",
                    }}
                    onClick={(e) => handleChange(e)}
                  />
                  <label htmlFor={`${keyProp}etc`} className="textLabel m-0">
                    기타
                  </label>
                  <input
                    type="text"
                    className="js-input-mask form-control customInput"
                    name={`${keyProp}etc`}
                    id={`${keyProp}etc`}
                    value={getInputValue(values, keyProp, value)}
                    style={{ marginLeft: "10px", width: "200px" }}
                    onChange={handleChangeEtc}
                    onBlur={handleChangeBlur}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "radio") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3" key={key}>
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <div className="d-flex pt-2 radiowrap">
                {value?.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex mr20 mb-2 align-items-center"
                  >
                    <input
                      type="radio"
                      name={keyProp}
                      id={`${keyProp}${item}Label`}
                      value={item}
                      checked={values?.[keyProp] === item}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                      onClick={(e) => handleChange(e)}
                    />
                    <label
                      htmlFor={`${keyProp}${item}Label`}
                      className="textLabel m-0"
                    >
                      {item}
                    </label>
                  </div>
                ))}
                {name === "주량" ? (
                  <div className="d-flex" style={{ alignItems: "flex-start" }}>
                    <label className="textLabel">소주</label>
                    <input
                      type="number"
                      className="js-input-mask customInput"
                      name="drinkBottleLimit"
                      value={values["drinkBottleLimit"]}
                      id={`${keyProp}Label`}
                      onWheel={(e) => e.target.blur()}
                      onChange={handleChange}
                      style={{
                        width: "44px",
                        padding: "0px 0px 0px 0.7rem",
                        textAlign: "end",
                      }}
                    />
                    <label className="textLabel">병</label>
                    <input
                      type="number"
                      className="js-input-mask customInput"
                      name="drinkCupLimit"
                      value={values["drinkCupLimit"]}
                      id={`${keyProp}Label`}
                      onWheel={(e) => e.target.blur()}
                      onChange={handleChange}
                      style={{
                        width: "44px",
                        padding: "0px 0px 0px 0.7rem",
                        textAlign: "end",
                      }}
                    />
                    <label className="textLabel">잔</label>
                  </div>
                ) : null}
                {name === "혼인 시 경제적 지원" ? (
                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      className="js-input-mask form-control customInput"
                      name="assistanceExtra"
                      id={`assistanceExtraLabel`}
                      value={values["assistanceExtra"]}
                      placeholder={`금액을 입력해 주세요.`}
                      aria-label={`금액을 입력해 주세요.`}
                      disabled={values?.merrymoney === "있음" ? false : true}
                      onChange={handleMoneyChange}
                    />
                  </div>
                ) : null}
                {keyProp === "smoke" ? (
                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      className="js-input-mask form-control customInput"
                      name="nosmoking"
                      id={`nosmokingLabel`}
                      value={values["nosmoking"]}
                      placeholder={`기간을 입력해 주세요.`}
                      aria-label={`기간을 입력해 주세요.`}
                      disabled={values?.smoke === "금연중" ? false : true}
                      onChange={handleChange}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </Col>
          {name === "혼인 시 경제적 지원" ? (
            values?.merrymoney === "있음" ? (
              <Col lg={12} className="d-flex flex-column">
                <div>{numberToKorean(values?.["assistanceExtra"])}</div>
              </Col>
            ) : null
          ) : null}
          {keyProp === "paalone" ? (
            <Col lg={12} className="d-flex flex-column">
              <div
                style={{
                  wordBreak: "keep-all",
                }}
              >
                기준 : 결혼 후 부모님의 생활비를 정기적으로 드려야 한다면 노후
                준비 여부 '불가'로 판단
              </div>
            </Col>
          ) : null}
        </Row>
      );
    }
    if (type === "check") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3" key={key}>
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <div className="d-flex pt-2 radiowrap">
                {attributes?.map((item, index) => (
                  <div key={index} className="d-flex mr20 mb-2">
                    <input
                      type="checkbox"
                      name={keyProp}
                      id={`${keyProp}${item.value}Label`}
                      value={item.id}
                      checked={values?.[keyProp]?.includes(Number(item.id))}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                      onClick={(e) => handleCheck(e)}
                    />
                    <label
                      htmlFor={`${keyProp}${item.value}Label`}
                      className="textLabel m-0"
                    >
                      {item.value}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "checks") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3" key={key}>
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <div className="d-flex pt-2 radiowrap">
                {value?.map((item, index) => (
                  <div key={index} className="d-flex mr20 mb-2">
                    <input
                      type="checkbox"
                      name={keyProp}
                      id={`${keyProp}${item}Label`}
                      value={item}
                      checked={values?.[keyProp]?.includes(item)}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                      onClick={(e) => handleCheckstr(e)}
                    />
                    <label
                      htmlFor={`${keyProp}${item}Label`}
                      className="textLabel m-0"
                    >
                      {item}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "외부 이미지") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3">
          <Col lg={12} className="d-flex flex-column">
            <label htmlFor={`publicLabel`} className="labelText">
              외부 이미지
            </label>
            <Label className="fileAddName m-0 h-100 d-flex align-items-center">
              <Input
                type="file"
                id="image"
                multiple
                onChange={onFileHandler1}
                key={filekey1}
              />
              <span
                style={{
                  backgroundColor: "#FF5913",
                  border: "1px solid #FF5913",
                  padding: "8px 12px",
                  borderRadius: "5px",
                  color: "#fff",
                  cursor: "pointer",
                }}
              >
                업로드
              </span>
            </Label>
          </Col>
          <Col
            lg={12}
            className="mt-3"
            style={
              publicPostfile?.length > 0 || values?.publicImages?.length > 0
                ? { display: "flex", flexDirection: "column" }
                : { display: "none" }
            }
          >
            <div
              className="d-flex"
              style={{
                minHeight: "100px",
                border: "0.5px solid #6e737c",
                padding: "20px",
                flexWrap: "wrap",
              }}
            >
              {publicPostfile?.length > 0
                ? publicPostfile?.map((item, i) => (
                    <div key={i} className="customimgWrap">
                      <span
                        style={{
                          backgroundColor: "#FF5913",
                          border: "1px solid #FF5913",
                          padding: "3px 8px",
                          borderRadius: "5px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                        className="customdanger"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          removeFile1(item.id);
                        }}
                      >
                        삭제
                      </span>
                      <a
                        href={`/image?src=${encodeURIComponent(
                          URL.createObjectURL(item.file)
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          alt="mainImg"
                          src={URL.createObjectURL(item.file)}
                          style={{
                            maxWidth: CommonConstant.userImageMaxWidth,
                            width: "100%",
                          }}
                          draggable
                          onDragStart={(e) => onDragalertStart(e, i)}
                        />
                      </a>
                    </div>
                  ))
                : ""}
              {values?.publicImages
                ? values?.publicImages?.map((item, i) => (
                    <div
                      key={i}
                      className="customimgWrap"
                      onDragOver={onDragOver}
                      onDrop={(e) => onDragDrop(e, i)}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        style={{
                          backgroundColor: "#FF5913",
                          border: "1px solid #FF5913",
                          padding: "3px 8px",
                          borderRadius: "5px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                        className="customdanger"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          deleteFile(item.id, "publicImages");
                        }}
                      >
                        삭제
                      </span>
                      <a
                        href={`/image?src=${encodeURIComponent(item.url)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          alt="mainImg"
                          src={item.url}
                          style={{
                            maxWidth: CommonConstant.userImageMaxWidth,
                            width: "100%",
                          }}
                          draggable
                          onDragStart={(e) => onDragStart(e, i)}
                          onDragOver={onDragOver}
                          onDrop={(e) => onDragDrop(e, i)}
                        />
                      </a>
                    </div>
                  ))
                : ""}
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "내부 이미지") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3">
          <Col lg={12} className="d-flex flex-column">
            <label htmlFor={`acceptLabel`} className="labelText">
              내부 이미지
            </label>
            <Label className="fileAddName m-0 h-100 d-flex align-items-center">
              <Input
                type="file"
                id="image"
                multiple
                onChange={onFileHandler2}
                key={filekey2}
              />
              <span
                style={{
                  backgroundColor: "#FF5913",
                  border: "1px solid #FF5913",
                  padding: "8px 12px",
                  borderRadius: "5px",
                  color: "#fff",
                  cursor: "pointer",
                }}
              >
                업로드
              </span>
            </Label>
          </Col>
          <Col
            lg={12}
            className="mt-3"
            style={
              privitePostfile?.length > 0 || values?.secretImages?.length > 0
                ? { display: "flex", flexDirection: "column" }
                : { display: "none" }
            }
          >
            <div
              className="d-flex"
              style={{
                minHeight: "100px",
                border: "0.5px solid #6e737c",
                padding: "20px",
                flexWrap: "wrap",
              }}
            >
              {privitePostfile?.length > 0
                ? privitePostfile?.map((item, i) => (
                    <div key={i} className="customimgWrap">
                      <span
                        style={{
                          backgroundColor: "#FF5913",
                          border: "1px solid #FF5913",
                          padding: "3px 8px",
                          borderRadius: "5px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                        className="customdanger"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          removeFile2(item.id);
                        }}
                      >
                        삭제
                      </span>
                      <a
                        href={`/image?src=${encodeURIComponent(
                          URL.createObjectURL(item.file)
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          alt="mainImg"
                          src={URL.createObjectURL(item.file)}
                          style={{
                            maxWidth: CommonConstant.userImageMaxWidth,
                            width: "100%",
                          }}
                          draggable
                          onDragStart={(e) => onDragalertStart(e, i)}
                        />
                      </a>
                    </div>
                  ))
                : ""}
              {values?.secretImages
                ? values?.secretImages?.map((item, i) => (
                    <div
                      key={i}
                      className="customimgWrap"
                      onDragOver={onDragPriviteOver}
                      onDrop={(e) => onDragPriviteDrop(e, i)}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        style={{
                          backgroundColor: "#FF5913",
                          border: "1px solid #FF5913",
                          padding: "3px 8px",
                          borderRadius: "5px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                        className="customdanger"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          deleteFile(item.id, "secretImages");
                        }}
                      >
                        삭제
                      </span>
                      <a
                        href={`/image?src=${encodeURIComponent(item.url)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          alt="mainImg"
                          src={item.url}
                          style={{
                            maxWidth: CommonConstant.userImageMaxWidth,
                            width: "100%",
                          }}
                          draggable
                          onDragStart={(e) => onDragPriviteStart(e, i)}
                          onDragOver={onDragPriviteOver}
                          onDrop={(e) => onDragPriviteDrop(e, i)}
                        />
                      </a>
                    </div>
                  ))
                : ""}
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "동영상 첨부") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3" key={key}>
          <Col lg={12} className="d-flex flex-column">
            <label htmlFor={`acceptLabel`} className="labelText">
              동영상 첨부
            </label>
            <Label className="fileAddName m-0 h-100 d-flex align-items-center">
              <Input
                type="file"
                id="image"
                multiple
                onChange={onVideoFileHandler}
                key={filekeyVideo}
              />
              <span
                style={{
                  backgroundColor: "#FF5913",
                  border: "1px solid #FF5913",
                  padding: "8px 12px",
                  borderRadius: "5px",
                  color: "#fff",
                  cursor: "pointer",
                }}
              >
                업로드
              </span>
            </Label>
          </Col>
          <Col
            lg={12}
            className="mt-3"
            style={
              videoPostfile?.length > 0 || values?.videos?.length > 0
                ? { display: "flex", flexDirection: "column" }
                : { display: "none" }
            }
          >
            <div
              className="d-flex"
              style={{
                minHeight: "100px",
                border: "0.5px solid #6e737c",
                padding: "20px",
                flexWrap: "wrap",
              }}
            >
              {videoPostfile?.length > 0
                ? videoPostfile?.map((item, i) => {
                    return (
                      <div className="customimgWrap">
                        <span
                          style={{
                            backgroundColor: "#FF5913",
                            border: "1px solid #FF5913",
                            padding: "3px 8px",
                            borderRadius: "5px",
                            color: "#fff",
                            cursor: "pointer",
                            zIndex: 1,
                          }}
                          className="customdanger"
                          onClick={() => removeFileVideo(item.id)}
                        >
                          삭제
                        </span>
                        <video
                          width="750"
                          height="500"
                          controls
                          style={{
                            maxWidth: CommonConstant.userImageMaxWidth,
                            maxHeight: CommonConstant.userImageMaxWidth,
                            width: "100%",
                          }}
                          draggable
                          onDragStart={(e) => onDragalertStart(e, i)}
                        >
                          <source
                            src={URL.createObjectURL(item.file)}
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    );
                  })
                : ""}
              {values?.videos
                ? values?.videos?.map((item, i) => {
                    return (
                      <div
                        className="customimgWrap"
                        onDragOver={onDragVideoOver}
                        onDrop={(e) => onDragVideoDrop(e, i)}
                        style={{ cursor: "pointer" }}
                        key={item.id}
                      >
                        <span
                          style={{
                            backgroundColor: "#FF5913",
                            border: "1px solid #FF5913",
                            padding: "3px 8px",
                            borderRadius: "5px",
                            color: "#fff",
                            cursor: "pointer",
                          }}
                          className="customdanger"
                          onClick={() => deleteFile(item.id, "videos")}
                        >
                          삭제
                        </span>
                        <video
                          width="750"
                          height="500"
                          controls
                          style={{
                            maxWidth: CommonConstant.userImageMaxWidth,
                            maxHeight: CommonConstant.userImageMaxWidth,
                            width: "100%",
                          }}
                          draggable
                          onDragStart={(e) => onDragVideoStart(e, i)}
                          onDragOver={onDragVideoOver}
                          onDrop={(e) => onDragVideoDrop(e, i)}
                        >
                          <source src={item.url} type="video/mp4" />
                        </video>
                      </div>
                    );
                  })
                : ""}
            </div>
          </Col>
        </Row>
      );
    }
  }

  return (
    <React.Fragment>
      {loading ? (
        <SweetAlert
          title="LOADING..."
          showConfirm={false}
          onConfirm={() => {
            setLoading(false);
          }}
          style={{ width: "20em" }}
        >
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <MetaTags>
          <title>회원 상세보기</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xl="12">
              <Nav tabs style={{ borderRadius: "0.25rem" }}>
                <NavItem
                  className={activeTab === 1 ? "customNavActive" : "customNav"}
                >
                  <NavLink
                    href="#"
                    onClick={() => {
                      toggleTab(1);
                    }}
                  >
                    회원 정보
                  </NavLink>
                </NavItem>
                <NavItem
                  className={activeTab === 2 ? "customNavActive" : "customNav"}
                >
                  <NavLink
                    href="#"
                    onClick={() => {
                      toggleTab(2);
                    }}
                  >
                    소개 목록
                  </NavLink>
                </NavItem>
                <NavItem
                  className={activeTab === 11 ? "customNavActive" : "customNav"}
                >
                  <NavLink
                    href="#"
                    onClick={() => {
                      toggleTab(11);
                    }}
                  >
                    소개된 목록
                  </NavLink>
                </NavItem>
                <NavItem
                  className={activeTab === 3 ? "customNavActive" : "customNav"}
                >
                  <NavLink
                    href="#"
                    onClick={() => {
                      toggleTab(3);
                    }}
                  >
                    보낸 호감 목록
                  </NavLink>
                </NavItem>
                <NavItem
                  className={activeTab === 4 ? "customNavActive" : "customNav"}
                >
                  <NavLink
                    href="#"
                    onClick={() => {
                      toggleTab(4);
                    }}
                  >
                    받은 호감 목록
                  </NavLink>
                </NavItem>
                <NavItem
                  className={activeTab === 5 ? "customNavActive" : "customNav"}
                >
                  <NavLink
                    href="#"
                    onClick={() => {
                      toggleTab(5);
                    }}
                  >
                    결제 목록
                  </NavLink>
                </NavItem>
                <NavItem
                  className={activeTab === 6 ? "customNavActive" : "customNav"}
                >
                  <NavLink
                    href="#"
                    onClick={() => {
                      toggleTab(6);
                    }}
                  >
                    매칭권 사용 목록
                  </NavLink>
                </NavItem>
                <NavItem
                  className={activeTab === 7 ? "customNavActive" : "customNav"}
                >
                  <NavLink
                    href="#"
                    onClick={() => {
                      toggleTab(7);
                    }}
                  >
                    마일리지 사용 목록
                  </NavLink>
                </NavItem>
                <NavItem
                  className={activeTab === 8 ? "customNavActive" : "customNav"}
                >
                  <NavLink
                    href="#"
                    onClick={() => {
                      toggleTab(8);
                    }}
                  >
                    결제 알림톡
                  </NavLink>
                </NavItem>
                <NavItem
                  className={activeTab === 9 ? "customNavActive" : "customNav"}
                >
                  <NavLink
                    href="#"
                    onClick={() => {
                      toggleTab(9);
                    }}
                  >
                    기간제
                  </NavLink>
                </NavItem>
                <NavItem
                  className={activeTab === 10 ? "customNavActive" : "customNav"}
                >
                  <NavLink
                    href="#"
                    onClick={() => {
                      toggleTab(10);
                    }}
                  >
                    인증 관련
                  </NavLink>
                </NavItem>
              </Nav>
              <Card style={{ borderTop: "none" }}>
                <CardBody>
                  <div className="bookmark-container">
                    <div
                      className="bookmarkremote"
                      onClick={() => setNavSee(!navSee)}
                    >
                      <Bars width={30} height={30} />
                    </div>

                    {tags?.map((item, i) => {
                      return (
                        <div
                          className={navSee ? "bookmark" : "bookmarkHidden"}
                          onClick={() => handleBookmarkClick(`section${item}`)}
                          style={
                            navSee
                              ? { visibility: "visible" }
                              : { visibility: "hidden" }
                          }
                          key={i}
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={1}>
                      <div className="wizard clearfix">
                        <div className="content clearfix">
                          {selOption?.map((el, i) => {
                            if (el.tag) {
                              return (
                                <section id={"section" + el.tag}>
                                  <div className="userCodewrap">
                                    <h3 className="MainText pt-3">{el.tag}</h3>
                                  </div>
                                  <p className="card-title-desc"></p>
                                  <Row className="rowBorderBottom" />
                                </section>
                              );
                            }
                            if (el.type === "check") {
                              return SelectFn({
                                name: el.name,
                                type: el.type,
                                keyProp: el.key,
                                value: el.value,
                                values,
                                key: i,
                                handleCheck,
                              });
                            } else if (el.type === "checks") {
                              return SelectFn({
                                name: el.name,
                                type: el.type,
                                keyProp: el.key,
                                value: el.value,
                                values,
                                key: i,
                                handleCheckstr,
                              });
                            } else if (el.type === "money") {
                              return SelectFn({
                                name: el.name,
                                type: el.type,
                                keyProp: el.key,
                                value: el.value,
                                values,
                                key: i,
                                handleChange,
                                handleMoneyChange,
                                handleChangeBoolean,
                              });
                            } else {
                              return SelectFn({
                                name: el.name,
                                keyProp: el.key,
                                value: el.value,
                                type: el.type,
                                values,
                                key: i,
                                handleChange,
                                handleMoneyChange,
                                handleChangeBoolean,
                              });
                            }
                          })}
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId={2}>
                      <div className="wizard clearfix">
                        <div className="content clearfix">
                          <IntroMemberList
                            id={id}
                            callAPI={callAPI}
                            resetAPI={activeTab === 2 ? true : false}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId={11}>
                      <div className="wizard clearfix">
                        <div className="content clearfix">
                          <HaveBeenIntroducedList
                            id={id}
                            callAPI={callAPI}
                            resetAPI={activeTab === 11 ? true : false}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId={3}>
                      <div className="wizard clearfix">
                        <div className="content clearfix">
                          <PostLikeList
                            id={id}
                            resetAPI={activeTab === 3 ? true : false}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId={4}>
                      <div className="wizard clearfix">
                        <div className="content clearfix">
                          <GetLikeList
                            id={id}
                            resetAPI={activeTab === 4 ? true : false}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId={5}>
                      <div className="wizard clearfix">
                        <div className="content clearfix">
                          <CashHistory
                            id={id}
                            callAPI={callAPI}
                            resetAPI={activeTab === 5 ? true : false}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId={6}>
                      <div className="wizard clearfix">
                        <div className="content clearfix">
                          <LikeHistory
                            id={id}
                            callAPI={callAPI}
                            likeTotal={values?.likeTotal}
                            likeFreeTotal={values.likeFreeTotal}
                            resetAPI={activeTab === 6 ? true : false}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId={7}>
                      <div className="wizard clearfix">
                        <div className="content clearfix">
                          <MileageHistory
                            id={id}
                            callAPI={callAPI}
                            resetAPI={activeTab === 7 ? true : false}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId={8}>
                      <div className="wizard clearfix">
                        <div className="content clearfix">
                          <CashPost
                            id={id}
                            handleChange={handleChange}
                            onClickSendPurchase={onClickSendPurchase}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId={9}>
                      <div className="wizard clearfix">
                        <div className="content clearfix">
                          <TimePage
                            id={id}
                            authData={authData}
                            resetAPI={activeTab === 9 ? true : false}
                          />
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId={10}>
                      <div className="wizard clearfix">
                        <div className="content clearfix">
                          <AuthPage id={values.profileId} authData={authData} />
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {activeTab === 1 ? (
            <Row>
              <Col className="d-flex justify-content-center">
                <Button
                  color="warning"
                  className="me-2"
                  onClick={() => onSubmitApi("ready")}
                >
                  수정
                </Button>
                <Button
                  color="danger"
                  className="me-2"
                  onClick={() => onSubmitApi("reject")}
                >
                  거절
                </Button>
                <Button
                  color="dark"
                  className="me-2"
                  onClick={() => onDeleteSubmitApi()}
                >
                  삭제
                </Button>
              </Col>
            </Row>
          ) : null}
        </Container>
      </div>
      {introMsg ? (
        <OneMoreIntroModal
          Msg={introMsg}
          setMsg={setIntroMsg}
          id={id}
          targetCode={values.targetCode}
          isPending={timesent}
          sendDate={`${date} ${hour}:${minute}:00`}
          setValues={setValues}
          values={values}
        />
      ) : null}
    </React.Fragment>
  );
}

export default Member;

const MailTipUl = styled.ul`
  width: 100%;
  max-height: 100px;
  position: absolute;
  border: 0.5px solid #6e737c !important;
  border-radius: 0 !important;
  background-color: #fff;
  padding-left: 0;
  z-index: 1;
  overflow: auto;
`;

const MailTipLi = styled.li`
  list-style: none;
  padding: 0.47rem 0.75rem;
  background-color: ${({ selected }) => (selected ? "#f5f5f5" : "")};
  color: ${({ selected }) => (selected ? "var(--zu--m4-color)" : "")};
`;
