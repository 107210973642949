import React, { useCallback, useEffect, useState } from "react";
import { useCashListStore } from "../../store/zustand/state";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useParams } from "react-router-dom";

import { adminUserPut, requestGet } from "../../apis/request";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Tables/datatables.scss";
import CashModal from "./Modal/CashModal";
import moment from "moment";
import PaginationAPIWrap from "../../components/PaginationAPIWrap";
import MobilePaginationAPIWrap from "../../components/MobilePaginationAPIWrap";
import CashHandModal from "./Modal/CashHandModal";
//lch1721 결제 목록 탭
function CashHistory({ id, callAPI, resetAPI }) {
  const [memberList, setMemberList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [value, setValue] = useState("");
  const [data, setData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [handModalOpen, setHandModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [dataId, setDataId] = useState("");
  const [username, setuserName] = useState("");
  const [manager, setManager] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [managers, setManagers] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [activeNum, setActiveNum] = useState(null);
  const [lastpage, setLastpage] = useState(null);
  const [pageNumber, setpageNumber] = useState([]);
  const [mobilepageNumber, setMobilepageNumber] = useState([]);

  const { list, updateList, updateActiveNum } = useCashListStore();

  const columns = [
    {
      dataField: "productName",
      text: "상품 이름",
      sort: true,
    },
    {
      dataField: "productPrice",
      text: "가격",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "구매 시간",
      sort: true,
    },
    {
      dataField: "status",
      text: "상태",
      sort: true,
    },
    {
      dataField: "action",
      text: "삭제",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <button
            onClick={(e) => deletePurchase(e, row.id, row.subscriptionId, row.productPrice)}
            className="btn btn-dark btn-sm"
          >
            삭제
          </button>
        );
      },
    },
  ];

  const callManagerApi = async () => {
    try {
      const response = await requestGet(`/api/counseling/manager/list`);
      setManagers(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    callManagerApi();
  }, []);

  const handleRowClick = (e, row) => {
    setDataId(row.id);
    setModalType("read");
    setModalOpen(true);
  };

  // rowEvents 객체 정의
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      handleRowClick(e, row, rowIndex);
    },
  };

  const callApi = async () => {
    const response = await requestGet(`/api/product/list/admin`);
    setData(response.data);
  };

  useEffect(() => {
    callApi();
  }, []);

  const callInfo = useCallback(async () => {
    try {
      const params = new URLSearchParams();
      if (list) {
        for (var key in list) {
          if (list[key]) {
            params.append(key, list[key]);
          }
        }
      }
      params.append("userId", id);
      const memberResponse = await requestGet(
        `/api/purchase/list?status=complete&${params}`
      );
      setMemberList(memberResponse?.data);
      setLastpage(memberResponse?.totalPage);
      setTotalCount(memberResponse?.totalCount);
    } catch (error) {
      console.log(error);
    }
  }, [list]);

  const deletePurchase = async (e, id, subscriptionId,productPrice) => {
    e.stopPropagation();
    if (window.confirm("정말 삭제 하시겠습니까?")) {
      try {
        let body = {
          purchaseId: id,
        };
        await adminUserPut(`/api/purchase/direct/cancel`, body);
        alert("삭제 되었습니다.");
        callInfo();
        callAPI();
      } catch (error) {
        console.log(error);
        if (error.data.message) {
          if(error.data.message.includes("구매 취소를하기위해서는 해당 기간제 상품에 대한 환불")){
            callRefund(subscriptionId, productPrice);
          } else {
            alert(error.data.message);
          }
          
        }
      }
    }
  };
  const callRefund = async (subscriptionId,productPrice) => {
    if (window.confirm("삭제전 환불이 필요한지 꼭 확인 부탁드립니다. 기간제 상품을 삭제하시겠습니까?")) {
      try {
        let body = {
          id: subscriptionId,
          refundPrice:productPrice,
          memo:"기간제 상품 삭제로 인한 환불",
          refundDate:moment().format("YYYY-MM-DD"),
        };
        await adminUserPut(`/api/subscription/refund`, body);
        alert("기간제 상품을 삭제하였습니다.");
        callInfo();
        callAPI();
      } catch (error) {
        if (error.data.error) {
          alert(error.data.error);
        } else if (error.data.message) {
          alert(error.data.message);
        } else {
          alert("요청 실패 하였습니다.");
        }
      }
    }
  };

  useEffect(() => {
    setActiveNum(list.page);
    setStartDate(list.startDate);
    setEndDate(list.endDate);
    if (id && resetAPI) {
      callInfo();
    }
  }, [callInfo, id, resetAPI, list]);

  useEffect(() => {
    if (memberList?.length > 0) {
      let arr = [];
      memberList.forEach((item) =>
        arr.push({
          
          id: item.id,
          orderer: item.orderer,
          gender: item.gender,
          phone: item.phone,
          email: item.email,
          status:
            item.status === "prepare"
              ? "결제 요청"
              : item.status === "complete"
              ? "결제 완료"
              : item.status === "refund-request"
              ? "환불 요청"
              : item.status === "refund-accept"
              ? "환불 완료"
              : "",
          createdAt: item.createdAt,
          productName: item.productName,
          productPrice: item.productPrice
            ? String(item.productPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : "",
          subscriptionId: item.subscriptionId,
        })
      );
      setProductData(arr);
    } else {
      setProductData([]);
    }
  }, [memberList]);

  const addModal = () => {
    setModalType("add");
    setModalOpen(true);
  };

  const handlename = (e) => {
    setuserName(e.target.value);
  };

  const onStartDate = (e) => {
    if (e.target.value) {
      setStartDate(moment(e.target.value).format("YYYY-MM-DD"));
    } else {
      setStartDate("");
    }
  };

  const onEndDate = (e) => {
    if (e.target.value) {
      setEndDate(moment(e.target.value).format("YYYY-MM-DD"));
    } else {
      setEndDate("");
    }
  };

  const gohistory = () => {
    updateList(1, "", "", startDate, endDate);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      gohistory();
    }
  };

  return (
    <React.Fragment>
      <h3 className="MainText pt-3">결제 목록</h3>
      <p className="card-title-desc"></p>
      <ToolkitProvider
        keyField="id"
        columns={columns}
        data={productData}
        search
      >
        {(toolkitProps) => (
          <React.Fragment>
            <Row>
              <Col lg="12">
                <Row
                  className="pb-4 m-0"
                  style={{ position: "sticky", left: 0 }}
                >
                  <Col lg="12" className="p-0">
                    <Row className="rowBorderBottom w-100 m-0  customSearchInputWrap">
                      <Col
                        lg={12}
                        className="d-flex justify-content-between"
                        style={{
                          padding: "0.47rem 0.75rem",
                          backgroundColor: "#ccc",
                          fontWeight: 700,
                        }}
                      >
                        <div>조회 조건</div>
                      </Col>
                      <Col lg={12} className="d-flex p-0 flex-column">
                        <Row className="w-100 m-0">
                          <Col
                            lg={2}
                            className="d-flex align-items-center"
                            style={{
                              padding: "0.47rem 0px 0.75rem 0.47rem",
                              backgroundColor: "#ccc",
                              fontWeight: 700,
                              borderTop: "0.3px solid #fff",
                              borderBottom: "0.3px solid #fff",
                            }}
                          >
                            <span>구매시간 시작일</span>
                          </Col>
                          <Col
                            className="m-0 p-0"
                            style={{
                              borderBottom: "1px solid #ced4da",
                            }}
                          >
                            <div className="h-100">
                              <input
                                type="date"
                                className="js-input-mask form-control noborder w-auto"
                                name="startDate"
                                id="startDateLabel"
                                max="9999-12-31"
                                value={startDate}
                                onChange={onStartDate}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={12} className="d-flex p-0 flex-column">
                        <Row className="w-100 m-0">
                          <Col
                            lg={2}
                            className="d-flex align-items-center"
                            style={{
                              padding: "0.47rem 0px 0.75rem 0.47rem",
                              backgroundColor: "#ccc",
                              fontWeight: 700,
                              borderTop: "0.3px solid #fff",
                              borderBottom: "0.3px solid #fff",
                            }}
                          >
                            <span>구매시간 종료일</span>
                          </Col>
                          <Col
                            className="m-0 p-0"
                            style={{
                              borderBottom: "1px solid #ced4da",
                            }}
                          >
                            <div className="h-100">
                              <input
                                type="date"
                                className="js-input-mask form-control noborder w-auto"
                                name="endDate"
                                id="endDateLabel"
                                max="9999-12-31"
                                value={endDate}
                                onChange={onEndDate}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col lg={12} className="d-flex p-0 flex-column">
                        <div>
                          <Button
                            className="w-100"
                            color="primary"
                            style={{ borderRadius: "0px" }}
                            onClick={() => gohistory()}
                          >
                            조회하기
                          </Button>
                        </div>
                      </Col>
                      <Col lg={12} className="d-flex p-0 flex-column">
                        <div>
                          <Button
                            className="w-50"
                            color="info"
                            style={{ borderRadius: "0px" }}
                            onClick={() => setHandModalOpen(true)}
                          >
                            수기등록
                          </Button>
                          <Button
                            className="w-50"
                            color="warning"
                            style={{ borderRadius: "0px" }}
                            onClick={() => addModal()}
                          >
                            등록하기
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xl="12">
                <div className="text-end customTotalcount">
                  검색결과 (
                  {String(totalCount).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  개의 검색결과가 있습니다)
                </div>
                <div className="table-responsive">
                  <BootstrapTable
                    keyField={"id"}
                    responsive
                    bordered={false}
                    striped={false}
                    classes={"table align-middle table-nowrap"}
                    headerWrapperClasses={"thead-light"}
                    {...toolkitProps.baseProps}
                    rowEvents={rowEvents}
                  />
                </div>
              </Col>
            </Row>
            {/* <div ref={btnref} style={{ height: "auto" }}> */}
            {!lastpage ? null : (
              <Row className="w-100 m-0 p-0 py-3">
                <Col className="w-100 m-0 p-0 d-none d-lg-flex">
                  <PaginationAPIWrap
                    pageNumber={pageNumber}
                    setpageNumber={setpageNumber}
                    lastpage={lastpage}
                    activeNum={activeNum}
                    setActiveNum={updateActiveNum}
                  />
                </Col>
                <Col className="w-100 m-0 p-0 d-flex d-lg-none">
                  <MobilePaginationAPIWrap
                    pageNumber={mobilepageNumber}
                    setpageNumber={setMobilepageNumber}
                    lastpage={lastpage}
                    activeNum={activeNum}
                    setActiveNum={updateActiveNum}
                  />
                </Col>
              </Row>
            )}
            {/* </div> */}
          </React.Fragment>
        )}
      </ToolkitProvider>
      {modalOpen ? (
        <CashModal
          setModal={setModalOpen}
          productData={data}
          setModalType={setModalType}
          modalType={modalType}
          userId={id}
          setDataId={setDataId}
          dataId={dataId}
          callInfo={callInfo}
          callAPI={callAPI}
        />
      ) : null}
      {handModalOpen ? (
        <CashHandModal
          setModal={setHandModalOpen}
          userId={id}
          callInfo={callInfo}
          callAPI={callAPI}
        />
      ) : null}
    </React.Fragment>
  );
}

export default CashHistory;
